// Date: 21 Jan 2025
import React, { useContext } from "react";
import { UserContext } from "../UserContext";
import Button from "../reusable/Button";
import { useLocation } from "react-router-dom";

// Date: 15 March 2025
export default function CnVersionNotAvailable() {
  const { darkTheme } = useContext(UserContext);
  // Date: 15 March 2025
  const location = useLocation();
  const fallbackVersion = location.state?.cn_fallback_version;
  const gettingLoginFunc = () => {
    // localStorage.removeItem("access_token");
    // localStorage.removeItem("cn_access_token");
    // localStorage.removeItem("uInfo");
    // localStorage.removeItem("msInfo");
    // localStorage.removeItem("previousUrl");
    // localStorage.removeItem("currentUrl");
    // localStorage.removeItem("versionNameId");
    // window.location.href = `/${fallbackVersion}`;
    // const fallbackVersion = localStorage.getItem("cn_fallback_version");
    window.location.href = `/${fallbackVersion ? fallbackVersion : ""}`;
  };
  return (
    <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
      <div className={`${darkTheme ? "text-Tomato" : "text-Tomato2"}`}>
        Version not available
      </div>
      <div
        className={`text-[14px] ${
          darkTheme ? "text-DarkGray" : "text-lighGray"
        }`}
      >
        Please, Go Back to Other Version
      </div>
      <Button onClick={() => gettingLoginFunc()} className={`!mt-4`}>
        Go to other Version
      </Button>
    </div>
  );
}
