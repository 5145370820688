// Date: 15 Jan, 2025
// Ref: Meeting for ask cn Versions control.
// Changes: Added VersionSelect file for verson switching.

import { useCallback, useContext, useEffect, useState } from "react";
import Select, { components } from "react-select";
import { debounce } from "lodash";
import AsyncSelect from "react-select/async";
import { UserContext } from "../UserContext";
import { BaseURL } from "../BaseURL";
import { useDispatch } from "react-redux";
import {
  fetchOldArticle,
  oldEmptyCompArticle,
} from "../Redux/features/CompareOldArticleSlice";

export const CompareVersionSelectOldArticle = ({
  selectedOption,
  setSelectedOption,
  // setIsVersionSelected,
}) => {
  const { darkTheme, tokenMode, selectedHighlight } = useContext(UserContext);
  const dispatch = useDispatch();
  // ACCESS TOKEN
  const accessToken = localStorage.getItem("access_token");
  // CN ACCESS TOKEN
  const cnAccessToken = localStorage.getItem("cn_access_token");

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Function to load options based on user input
  const loadOptions = (inputValue, callback) => {
    fetch(`${BaseURL}/${tokenMode}/versions?q=${inputValue}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${
          tokenMode === "personal" ? accessToken : cnAccessToken
        }`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const options = data.data.map((item) => ({
          label: `Version- ${item.name}`,
          value: item.id,
          slug: item.slug,
        }));
        callback(options);
        setOptions(options);
        // setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        callback([]);
        // setIsLoading(false)
      });
  };
  const debouncedLoadOptions = debounce(loadOptions, 400);

  // Handle option select
  const handleChange = async (option) => {
    setSelectedOption(option);
    dispatch(oldEmptyCompArticle());
    if (option?.value) {
      // setIsVersionSelected(true);
      dispatch(
        fetchOldArticle({
          versionSlug: option.slug,
          tokenMode,
          selectedHighlight,
        })
      );
    }
  };

  // Custom styling
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      margin: "0 auto",
      color: `${darkTheme ? "white" : "black"}`,
    }),
    control: (provided) => ({
      ...provided,
      // backgroundColor: "#F6F8FC",
      backgroundColor: `${darkTheme ? "#262626" : "#ffffff"}`,
      borderColor: "none",
      boxShadow: "none",
      "&:hover": {
        borderColor: "none",
      },
      borderRadius: "12px",
      border: "none",
      // padding: "0px 2px",
      // textTransform: "uppercase",
      // border: "1px solid #E5E5E5",
      fontSize: "14px",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      // backgroundColor: `${darkTheme ? "#52565e" : "#F6F8FC"}`,
      // textTransform: "uppercase",
      // border: "1px solid #E5E5E5",
      padding: "0 6px",
      width: "166px",
    }),
    // menuList: (provided) => ({
    //   ...provided,
    //   backgroundColor: `${darkTheme ? "#52565e" : "#F6F8FC"}`, // Ensure the list inside matches the menu background
    //   // padding: 0,
    // }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#0a61dc"
        : state.isFocused
        ? "#fff"
        : "#fff",
      color: state.isSelected ? "white" : "black",
      "&:active": {
        backgroundColor: "#aaa",
        color: "white",
      },
      padding: "4px 10px",
      borderRadius: "8px",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: `${darkTheme ? "white" : "black"}`,
      // backgroundColor: `${darkTheme ? "#52565e" : "#F6F8FC"}`,
      // padding: "2px 6px",
      // borderRadius: "4px"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: `${darkTheme ? "#0f99ff" : "#0a61dc"}`,
      fontWeight: "400",
      fontSize: "14px",
      // textTransform: "uppercase",
    }),
    input: (provided) => ({
      ...provided,
      color: `${darkTheme ? "white" : "black"}`,
    }),
  };

  return (
    <AsyncSelect
      styles={customStyles}
      cacheOptions
      loadOptions={debouncedLoadOptions}
      defaultOptions
      onChange={handleChange}
      value={selectedOption}
      // defaultValue={defValue}
      placeholder="Select Version"
      isLoading={isLoading}
      // components={{
      //   MenuList: (props) => <MenuList {...props} isLoading={isLoading} />,
      // }}
      closeMenuOnSelect={true}
      className="react-select-container"
      classNamePrefix="react-select"
      // isClearable
      isDisabled={isLoading}
      isSearchable={false}
    />
  );
};
