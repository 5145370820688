import { BrowserRouter as Router } from "react-router-dom";
import MainWrapper from "./MainWrapper";
// 17 March 2025
export default function App() {
  return (
    <>
      <Router>
        <MainWrapper />
      </Router>
    </>
  );
}
