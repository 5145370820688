import React, { useContext } from "react";
import { motion } from "framer-motion";
import { UserContext } from "../../UserContext";

export default function ArticleNotFoundModal({ setIsArticleNotFound }) {
  const { darkTheme } = useContext(UserContext);
  return (
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 1, y: 25 }}
      transition={{ delay: 0.2, type: "easeInOut", duration: 0.5 }}
      className={`relative mx-4 w-full md:w-[340px] rounded-xl p-2 md:p-4 shadow ${
        darkTheme ? "bg-DarkGray" : "bg-Bg"
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex items-center justify-center flex-col gap-4">
        <div>
          <div className="my-1 text-center">Article Not Found!</div>
          <div className={`text-sm ${darkTheme ? "" : ""}`}>
            Please contact CloudFrame Support for assistance.
          </div>
        </div>

        <button
          className={`py-1 px-4 rounded-xl transition-all ${
            darkTheme
              ? "bg-Icon active:bg-gray hover:bg-LightBlack"
              : "bg-Border active:bg-White hover:bg-Hover"
          }`}
          onClick={() => setIsArticleNotFound(false)}
        >
          Go Back
        </button>
      </div>
    </motion.div>
  );
}
