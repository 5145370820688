import { useContext, useEffect, useRef, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { UserContext } from "./components/UserContext";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Callback } from "./components/Oauth2/Callback";
import Login from "./components/pages/Login";
import PageNotFound from "./components/pages/PageNotFound";
import Layout from "./components/reusable/Layout";
import Home from "./components/pages/Home";
import { BaseURL } from "./components/BaseURL";
import toast from "react-hot-toast";
import "react-tippy/dist/tippy.css";
import OdPageNotFound from "./components/pages/OdPageNotFound";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ProfileContent } from "./msal-login/ProfileContent";
import GetLocalStorageValue from "./components/reusable/GetLocalStorageValue";
import Button from "./components/reusable/Button";
import CnVersionNotAvailable from "./components/pages/CnVersionNotAvailable";
import ArticleNotAvailable from "./components/pages/ArticleNotAvailable";

const GlobalStyle = createGlobalStyle`
body{
  background: ${({ darkTheme }) => (darkTheme ? "#262626" : "#eff3f8")};
  color: ${(props) => (props.darkTheme ? "#E5E0D8" : "#000000")};
}
`;

export default function MainWrapper() {
  const {
    darkTheme,
    setDarkTheme,
    setIsSearchOpen,
    allPosts,
    setAllPosts,
    setBodyData,
    bodyData,
    selectedHighlight,
    setSelectedHighlight,
    resToken,
    setResToken,
    loginDataLoading,
    codeNavigatorToken,
    tokenMode,
    setCodeNavigatorToken,
    setOpenMenu,
    setBreadCrumbCategory,
    setBreadCrumb,
    setUrlTagIdStore,
    setInitialLeftScrollTrue,
    leftScrollFlag,
    setLeftScrollFlag,
    setPostId,

    setIsOpenLink,
    isOpenLink,
    isMsalLogin,
    setIsMsalLogin,
    setAllowFeedback,
    setCnUserInfo,
    cnUserInfo,
    versionName,
    setVersionName,
    isVersionNameClicked,
    versionNameInitialSlugSet,
    setVersionNameInitialSlugSet,
    // currentArticleAddrRefresh,
    setIsVersionNameClicked,
  } = useContext(UserContext);
  const [postLoader, setPostLoader] = useState(true);
  const [odLoader, setOdLoader] = useState(false);
  const [emptyPostLoader, setEmptyPostLoader] = useState(false);
  const [postLoaderHandler, setPostLoaderHandler] = useState(false);
  const [errMsgForPostLoaderHandler, setErrMsgForPostLoaderHandler] =
    useState(null);

  // Date: 15 March 2025
  const navigate = useNavigate();

  // Date: 28 Feb 2025
  let getCurrentArticleAddress = localStorage.getItem("currentArticleAddr");
  // useEffect(() => {
  //   getCurrentArticleAddress = localStorage.getItem("currentArticleAddr");
  // }, [currentArticleAddrRefresh]);
  // console.log("getCrr=>", getCurrentArticleAddress);

  // Date: 27 Feb 2025
  const [isArticleNotFound, setIsArticleNotFound] = useState(false);
  const [isOdArticleNotFound, setIsOdArticleNotFound] = useState(false);

  // 28 Jan 2025
  // Changes: Added codes for od link error handler.
  const [odPostLoaderHandler, setOdPostLoaderHandler] = useState(false);

  const [failedToFetchLoaderHandler, setFailedToFetchLoaderHandler] =
    useState(false);

  // Date: 15 Feb 2025
  // Ref: "As per Manigandan";
  // Changes: "Added new handler variable to handle article not matched show page";
  const [articleNotMatchedLoaderHandler, setArticleNotMatchedLoaderHandler] =
    useState(false);
  const [odFailedToFetchLoaderHandler, setoDFailedToFetchLoaderHandler] =
    useState(false);

  // const [isCNtoken, setIsCNtoken] = useState(false);

  // const navigate = useNavigate();
  // THEME toggle by SYSTEM
  const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  useEffect(() => {
    const handleChange = (e) => {
      // setDarkTheme(mediaQuery.matches ? 'dark' : 'light');
      setDarkTheme(e.matches ? true : false);
    };

    // Listen for system theme changes
    mediaQuery.addEventListener("change", handleChange);

    return () => mediaQuery.removeEventListener("change", handleChange);
  }, [mediaQuery?.matches]);

  // ACCESS TOKEN
  const accessToken = localStorage.getItem("access_token");
  // CN ACCESS TOKEN
  const cnAccessToken = localStorage.getItem("cn_access_token");

  let gettingLocalStrVersionNameClickedId =
    localStorage.getItem("versionNameId");

  const hasMounted = useRef(false);

  const url = window.location.href;
  // const endPath = url.split("/").pop();
  // let codeNavigatorSlugLogin = url.includes("/auth?token=")
  let codeNavigatorSlugLogin = url.includes("/auth?token=")
    ? false
    : url.includes("token=")
      ? true
      : false;

  if (url.includes("/auth?token=")) {
    localStorage.removeItem("cn_access_token");
  }

  // console.log("cnSlugLogin===>", codeNavigatorSlugLogin);

  // const cleanUrl = url.split("#")[0];
  // const endPath = cleanUrl.split("/").pop();
  // const tagIdUrl = url.split("#")[1] || "";   // 15 nov 2024

  // const endPath = url.split("/").pop();

  // const cleanUrl = url.split("#")[0];
  // const endPath = cleanUrl.split("/").pop();
  // const tagIdUrl = url.split("#")[1] || "";

  const cleanUrl = url.split("#")[0];
  // expressions for IDENTIFICATION
  const parsedUrl = new URL(cleanUrl);
  // Get the path and remove the leading "/"
  // const pathWithoutLeadingSlash = parsedUrl.pathname.slice(1);
  // const endPath1 = parsedUrl.pathname.slice(1);
  const endPath1 = parsedUrl.pathname.slice(1);
  // console.log("end-path-1==>", endPath1);
  // console.log("parsedUrl==>", parsedUrl);
  if (codeNavigatorSlugLogin) {
    localStorage.removeItem("cn_access_token");
    localStorage.removeItem("cn_rt");
    // setIsCNtoken(true)
    const slugByCN = endPath1;
    // const slugByCN = endPath1.slice(0, -5);
    localStorage.setItem("sByCN", slugByCN);

    // console.log("slugByCN===>", slugByCN);
  }
  if (tokenMode === "codenavigator") {
    const cnSlug = localStorage.getItem("sByCN");
    codeNavigatorSlugLogin = false;
    // setIsCNtoken(true)
    // localStorage.removeItem("sByCN");
  }
  // const endPath = codeNavigatorSlugLogin
  //   ? endPath1.slice(0, -5)
  //   : parsedUrl.pathname.slice(1);

  // const endPath =
  //   tokenMode === "codenavigator" || codeNavigatorSlugLogin
  //     ? codeNavigatorSlugLogin
  //       ? endPath1
  //       : localStorage.getItem("sByCN")
  //       ? localStorage.getItem("sByCN")
  //       : parsedUrl.pathname.slice(1)
  //     : parsedUrl.pathname.slice(1);
  // const tagIdUrl = url.split("#")[1] || ""; // 19 nov codes 2024
  const endPath2 =
    tokenMode === "codenavigator" || codeNavigatorSlugLogin
      ? codeNavigatorSlugLogin
        ? endPath1
        : localStorage.getItem("sByCN")
          ? localStorage.getItem("sByCN")
          : parsedUrl.pathname.slice(1)
      : parsedUrl.pathname.slice(1);
  const tagIdUrl = url.split("#")[1] || ""; // 15 Jan codes 2025

  // ------------------------Version-identify----------------------------------
  // Date: 15 Jan, 2025
  // Ref: Meeting for ask cn Versions control.
  // Changes: Added codes for verson switching.
  // Regular expression to match the version part (e.g., versionX.Y.Z)
  const [urlParamsVersionNameId, setUrlParamsVersionNameId] = useState(null);
  const gettingVersionNameByURLParams = async (v) => {
    const res = await fetch(`${BaseURL}/${tokenMode}/versions`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${tokenMode === "personal" ? accessToken : cnAccessToken
          }`,
      },
    });
    const data = await res.json();
    if (res.ok) {
      // const version = await data.data.find((item) => {
      //   if (item.slug === v) {
      //     return {
      //       value: item.id,
      //       name: item.name,
      //       slug: item.slug,
      //     };
      //   }
      // });
      const version = await data.data.find((item) => item.slug === v);
      // Date: 17 Jan, 2025
      // Ref: Meeting for ask cn Versions control.
      // Changes: Added codes for version switching.
      if (!versionName) {
        // localStorage.setItem("versionNameId", version?.id ? version.id : "");
        localStorage.setItem(
          "versionNameId",
          version?.slug ? version.slug : ""
        );
      }
      await setVersionName(version.slug);
      // setUrlParamsVersionNameId(version.id);
      setUrlParamsVersionNameId(version.slug);
    }
  };
  // const versionRegex = /version(\d+\.\d+\.\d+)/;
  // const versionRegex = /^(\d+\.\d+\.\d+)/;

  // const versionRegex = /^\d{1,3}(\.\d{1,3}){1,3}$/;
  // const versionRegex = /^\d{1,3}(\.\d{1,3}){1,3}/;

  // Date: 24 Feb 2025
  const versionRegex = /\b\d{1,3}(\.\d{1,3}){1,3}\b/; // best math[0] not match[1]

  const versionMatch = endPath2.match(versionRegex);
  let version = null;
  // if (versionMatch) {
  //   version = versionMatch[1]; // Extract '2.0.0' from 'version2.0.0'
  //   // setVersionName(2);
  //   gettingVersionNameByURLParams(version);
  //   console.log("versisss==>", version);
  // }

  // Remove the version part from the URL
  // const cleanedUrl = endPath2.replace(versionRegex, '').replace(/^\/|\/$/g, '');
  const endPath = endPath2.replace(versionRegex, "").replace(/^\/|\/$/g, "");
  // console.log("end-path==>", endPath);
  // ----------------------------------------------------------

  if (tagIdUrl) {
    setUrlTagIdStore(tagIdUrl);
  }

  const cnAccessPath = new URLSearchParams(window.location.search);
  const queryByCN = cnAccessPath.get("token");

  // Date: 1 April 2025 --------start
  if (versionMatch) {
    localStorage.setItem("odVersion", versionMatch[0]);
  }
  // Date: 1 April 2025 --------end

  // GETTING ALL POST
  const getAllPostFunc = async () => {
    setPostLoader(true);
    setResToken("");
    setCodeNavigatorToken("");

    if (url.includes("/od")) {
      // my Error Handling failed to fetch start
      try {
        setIsOpenLink("od");

        //Date: 7 Feb 2025
        //Ref: Working on as per Manigandan, (Production site) in od(open document) by OD link, user see only one article that we will share the article links to users. That is added to identify specific article.
        // Changes: Added new Codes.

        const gettingPostSlugByod = endPath.replace("od/", "");

        // Date: 1 April 2025----------------------start
        const gettingCnUserInfoDataForOd = GetLocalStorageValue("cn_userInfo", {});
        const getVersionForOd = localStorage.getItem("odVersion");

        const versionNameForLoginUserOd = getVersionForOd ? getVersionForOd : "";

        const versionNameForCnUserOd = gettingCnUserInfoDataForOd?.platform_version ? gettingCnUserInfoDataForOd.platform_version : "";

        const versionNameForOd = tokenMode === "personal" ? versionNameForLoginUserOd : versionNameForCnUserOd;

        const versionDataOd = {
          version: versionNameForOd,
        };
        const queryParamsOd = new URLSearchParams(versionDataOd).toString();
        const versionValidateOd = queryParamsOd ? queryParamsOd : "";
        // Date: 1 April 2025----------------end

        // OPEN LINK FETCHING
        // const res = await fetch(`${BaseURL}/od/posts/${gettingPostSlugByod}`, {
        // Date: 1 April 2025 Prodn
        // Changes: to open od article with version name article.
        // Ref: "As per Manigandan."
        const res = await fetch(`${BaseURL}/od/posts/${gettingPostSlugByod}?${versionValidateOd}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            // Authorization: `Bearer ${
            //   tokenMode === "personal" ? accessToken : cnAccessToken
            // }`,
          },
        });
        const data = await res.json();

        if (data?.success) {
          setPostLoader(true);

          // await setAllPosts(data.data);
          // COUNTS hEADING using DOMparser
          const parseHTMLAndCountHeadings = (dataArray) => {
            return dataArray.map((user) => {
              // Map through each user's posts
              const updatedPosts = user.posts.map((post) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(post.body, "text/html");

                // Count all headings h1, h2, h3, and h4
                const count = {
                  h1: doc.getElementsByTagName("h1").length,
                  // h2: doc.getElementsByTagName("h2").length,
                  // h3: doc.getElementsByTagName("h3").length,
                  // h4: doc.getElementsByTagName("h4").length,
                };

                // Return a new post object with the count key added
                return {
                  ...post,
                  count,
                };
              });

              // Return a new user object with the updated posts
              return {
                ...user,
                posts: updatedPosts,
              };
            });
          };

          const newUpdatedData = await parseHTMLAndCountHeadings(data?.data);
          await setAllPosts(newUpdatedData);

          // console.log("new", newUpdatedData);
          // console.log("old", allPosts);
          // END HEADING COUNTS

          const updatedData = await data.data.map((item) => {
            return {
              ...item,
              posts: item.posts.map((post) => ({
                ...post,
                name: item.name,
              })),
            };
          });
          // console.log("myAllPoost", updatedData)
          const myAllPosts = await updatedData.flatMap(
            (category) => category.posts
          );
          if (endPath !== ("od" || "od/") && !queryByCN) {
            setPostLoader(true);
            // setAllPosts(data.data);
            // const myAllPosts = await data.data.flatMap(
            //   (category) => category.posts
            // );

            const slugByod = endPath.replace("od/", "");
            const filtered = await myAllPosts.filter(
              // (post) => post.slug === endPath
              (post) => post.slug === slugByod
            );

            if (filtered.length > 0) {
              setPostLoader(true);
              // setInitialLeftScrollTrue(true)

              await setBodyData(filtered[0].body);
              await setSelectedHighlight(filtered[0].slug);
              await setBreadCrumbCategory(filtered[0].name);
              await setBreadCrumb(filtered[0].title);
              await setPostId(filtered[0].id);
              // await setOpenMenu(0);
              await setResToken(accessToken || "");
              await setCodeNavigatorToken(cnAccessToken || "");

              setPostLoader(false);
              // setInitialLeftScrollTrue(false)
              setInitialLeftScrollTrue(true);
              setLeftScrollFlag(true);
            } else {
              // toast.error("Wrong address, Go to home address");

              // Date: 8 Feb 2025
              // Ref: Self analyse the optimization and improvements of user experience.
              // Changes: "Update error information"
              // toast.error("Article not found. Go back to the Home Page");
              toast.error("Article not found");
              // navigate("/od/page-not-found");
              // setTimeout(() => {
              //   window.location.href = "/od";
              // }, 1000);

              // Date: 27 Feb 2025
              setPostLoader(false);
              setIsOdArticleNotFound(true);
            }
          } else {
            if (myAllPosts.length > 0) {
              setPostLoader(true);
              // setInitialLeftScrollTrue(true)

              // setAllPosts(data.data);

              // await setBodyData(data.data[0].posts[0].body);
              // await setSelectedHighlight(data.data[0].posts[0].slug);
              // await setBreadCrumbCategory(data.data[0].posts[0].name);
              // await setBreadCrumb(data.data[0].posts[0].title);

              await setSelectedHighlight(myAllPosts[0].slug);
              await setBodyData(myAllPosts[0].body);
              await setBreadCrumbCategory(myAllPosts[0].name);
              await setBreadCrumb(myAllPosts[0].title);
              await setPostId(myAllPosts[0].id);

              // await setOpenMenu(0);
              await setResToken(accessToken || "");
              await setCodeNavigatorToken(cnAccessToken || "");

              setPostLoader(false);
              // setInitialLeftScrollTrue(false)
              setInitialLeftScrollTrue(true);
              setLeftScrollFlag(true);
            } else {
              // toast.error("Data not available in the selected version.");

              // Date: 20 Jan, 2025
              // Ref: MySelf.
              // Changes: Improve Error Handling.
              setPostLoader(false);
              setOdLoader(true);
            }
          }
        } else if (data?.message === "Unauthenticated.") {
          setPostLoader(true);

          setAllPosts([]);
          setResToken("");
          setCodeNavigatorToken("");
          setPostLoader(false);
          setInitialLeftScrollTrue(false);
        } else {
          setPostLoader(true); //important
          setInitialLeftScrollTrue(false);

          setAllPosts([]);
          // toast.error("Unauthorized User !");
          setResToken("");
          setCodeNavigatorToken("");
          // setPostLoader(false);

          // IN OD INTERNAL 500 ERROR
          // toast.error("Wrong address OR There is no internet connection");

          // Date: 28 Jan 2025
          setPostLoader(false);
          setOdPostLoaderHandler(true);
        }
        if (bodyData) {
          setInitialLeftScrollTrue(false);
          setLeftScrollFlag(false);
        } else {
          setInitialLeftScrollTrue(false);
          setLeftScrollFlag(false);
        }
      } catch (err) {
        if (err.message === "Failed to fetch") {
          // toast.error(
          //   "Network error: Unable to fetch data. Please check your connection."
          // );
          setPostLoader(false);
          setoDFailedToFetchLoaderHandler(true);
        }
        // else{
        //   toast.error(`An error occurred: ${err.message}`)
        // }
      }
      // my Error Handling failed to fetch end
    } else {
      try {
        //  my ORIGINAL LOGIN start

        // Date: 15 Jan, 2025
        // Ref: Meeting for ask cn Versions control.
        // Changes: Added queryParams codes for version switching.

        // Date: 17 Jan, 2025
        // Ref: Meeting for ask cn Versions control.
        // Changes: Added codes for version switching.
        gettingLocalStrVersionNameClickedId =
          localStorage.getItem("versionNameId");

        // Date: 24 Feb 2025,  version testing
        // console.log("version-regex", versionRegex)
        // console.log("version-match", versionMatch)
        // return alert("hiiii")

        if (versionMatch) {
          // version = versionMatch[1]; // Extract '2.0.0' from 'version2.0.0'
          version = versionMatch[0];
          // console.log("version-match", version);
          // return alert("hiiii");

          // setVersionName(2);
          await gettingVersionNameByURLParams(version);

          // 8 Jan 2025
          // Ref: Observe to improve routes.
          // Changes: "If we pase defferent article and version and that is available, but we are in different version then it will switch target version"
          gettingLocalStrVersionNameClickedId =
            localStorage.getItem("versionNameId");
        }
        // Data to send
        // const versionData = {
        //   version: versionName?.value
        //     // ? versionName.value
        //     // : versionName?.id
        //     // ? versionName.id
        //     // : "",
        // };

        const versionData = {
          version: gettingLocalStrVersionNameClickedId
            ? gettingLocalStrVersionNameClickedId
            : urlParamsVersionNameId
              ? urlParamsVersionNameId
              : "",
        };
        // Convert the data to query string
        const queryParams = new URLSearchParams(versionData).toString();
        // const versionValidate = queryParams?.version !== "" ? queryParams : "";
        const versionValidate = queryParams ? queryParams : "";
        // const versionValidate = queryParams;
        // WITH CREDENTIALS

        // Date: 15 March 2025 ----holi---------start
        // if (endPath2 === "version_not_available_with_cn") {
        //   toast.error("ok fine");
        //   setPostLoader(false);
        //   navigate("/version_not_available_with_cn");
        //   return;
        // }
        // Date: 15 March 2025 -------------end

        const res = await fetch(
          `${BaseURL}/${tokenMode}/posts?${versionValidate}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${tokenMode === "personal" ? accessToken : cnAccessToken
                }`,
            },
          }
        );
        const data = await res.json();

        if (data?.success) {
          setPostLoader(true);

          // SET-VERSION SELECT
          // setVersionName(data?.version?.id);
          const versionSlug = data?.version?.slug ? data.version.slug : "";
          setVersionName(versionSlug);
          setVersionNameInitialSlugSet(versionSlug);
          // localStorage.setItem(
          //   "versionNameId",
          //   data?.version?.id ? data.version.id : ""
          // );

          // await setAllPosts(data.data);
          // COUNTS hEADING using DOMparser
          const parseHTMLAndCountHeadings = (dataArray) => {
            return dataArray.map((user) => {
              // Map through each user's posts
              const updatedPosts = user.posts.map((post) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(post.body, "text/html");

                // Count all headings h1, h2, h3, and h4
                const count = {
                  h1: doc.getElementsByTagName("h1").length,
                  // h2: doc.getElementsByTagName("h2").length,
                  // h3: doc.getElementsByTagName("h3").length,
                  // h4: doc.getElementsByTagName("h4").length,
                };

                // Return a new post object with the count key added
                return {
                  ...post,
                  count,
                };
              });

              // Return a new user object with the updated posts
              return {
                ...user,
                posts: updatedPosts,
              };
            });
          };

          const newUpdatedData = await parseHTMLAndCountHeadings(data?.data);
          await setAllPosts(newUpdatedData);

          // console.log("new", newUpdatedData);
          // console.log("old", allPosts);
          // END HEADING COUNTS

          const updatedData = await data.data.map((item) => {
            return {
              ...item,
              posts: item.posts.map((post) => ({
                ...post,
                name: item.name,
              })),
            };
          });
          // console.log("myAllPoost", updatedData)
          const myAllPosts = await updatedData.flatMap(
            (category) => category.posts
          );
          if (endPath && !queryByCN) {
            // if (endPath) {
            setPostLoader(true);
            // setAllPosts(data.data);
            // const myAllPosts = await data.data.flatMap(
            //   (category) => category.posts
            // );

            const filtered = await myAllPosts.filter(
              (post) => post.slug === endPath
            );

            if (filtered.length > 0) {
              setPostLoader(true);
              // setInitialLeftScrollTrue(true)

              localStorage.removeItem("sByCN");

              await setBodyData(filtered[0].body);
              await setSelectedHighlight(filtered[0].slug);
              await setBreadCrumbCategory(filtered[0].name);
              await setBreadCrumb(filtered[0].title);
              await setPostId(filtered[0].id);
              await setAllowFeedback(filtered[0].post_feedbacks_count);
              // await setOpenMenu(0);
              await setResToken(accessToken || "");
              await setCodeNavigatorToken(cnAccessToken || "");

              setPostLoader(false);
              // setInitialLeftScrollTrue(false)
              setInitialLeftScrollTrue(true);
              setLeftScrollFlag(true);
            } else {
              // toast.error("Wrong address, Go to home address");
              // localStorage.removeItem("versionNameId");
              localStorage.removeItem("sByCN");
              // toast.error("Article not found. Going back to the Home Page");
              // setTimeout(() => {
              //   window.location.href = "/";
              // }, 1000);

              // Date: 27 Feb 2025
              setPostLoader(false);
              setIsArticleNotFound(true);
              // window.location.href = "/article-not-available";
            }
          } else if (endPath && queryByCN) {
            setPostLoader(true);
            // setAllPosts(data.data);
            // const myAllPosts = await data.data.flatMap(
            //   (category) => category.posts
            // );

            const filtered = await myAllPosts.filter(
              (post) => post.slug === endPath
            );

            if (filtered.length > 0) {
              setPostLoader(true);
              // setInitialLeftScrollTrue(true)

              localStorage.removeItem("sByCN");

              await setBodyData(filtered[0].body);
              await setSelectedHighlight(filtered[0].slug);
              await setBreadCrumbCategory(filtered[0].name);
              await setBreadCrumb(filtered[0].title);
              await setPostId(filtered[0].id);
              await setAllowFeedback(filtered[0].post_feedbacks_count);
              // await setOpenMenu(0);
              await setResToken(accessToken || "");
              await setCodeNavigatorToken(cnAccessToken || "");

              setPostLoader(false);
              // setInitialLeftScrollTrue(false)
              setInitialLeftScrollTrue(true);
              setLeftScrollFlag(true);
            }
            // else {
            //   // toast.error("Wrong address, Go to home address");
            //   toast.error("Oops! Incorrect Address. Go back to the Home Page");
            // }
            else {
              setPostLoader(true);
              // setInitialLeftScrollTrue(true)

              localStorage.removeItem("sByCN");

              // setAllPosts(data.data);

              // await setBodyData(data.data[0].posts[0].body);
              // await setSelectedHighlight(data.data[0].posts[0].slug);
              // await setBreadCrumbCategory(data.data[0].posts[0].name);
              // await setBreadCrumb(data.data[0].posts[0].title);

              await setSelectedHighlight(myAllPosts[0].slug);
              await setBodyData(myAllPosts[0].body);
              await setBreadCrumbCategory(myAllPosts[0].name);
              await setBreadCrumb(myAllPosts[0].title);
              await setPostId(myAllPosts[0].id);
              await setAllowFeedback(myAllPosts[0].post_feedbacks_count);

              // await setOpenMenu(0);
              await setResToken(accessToken || "");
              await setCodeNavigatorToken(cnAccessToken || "");

              setPostLoader(false);
              // setInitialLeftScrollTrue(false)
              setInitialLeftScrollTrue(true);
              setLeftScrollFlag(true);
            }
          } else {
            if (myAllPosts.length > 0) {
              setPostLoader(true);
              // setInitialLeftScrollTrue(true)

              localStorage.removeItem("sByCN");

              // setAllPosts(data.data);

              // await setBodyData(data.data[0].posts[0].body);
              // await setSelectedHighlight(data.data[0].posts[0].slug);
              // await setBreadCrumbCategory(data.data[0].posts[0].name);
              // await setBreadCrumb(data.data[0].posts[0].title);

              await setSelectedHighlight(myAllPosts[0].slug);
              await setBodyData(myAllPosts[0].body);
              await setBreadCrumbCategory(myAllPosts[0].name);
              await setBreadCrumb(myAllPosts[0].title);
              await setPostId(myAllPosts[0].id);
              await setAllowFeedback(myAllPosts[0].post_feedbacks_count);

              // await setOpenMenu(0);
              await setResToken(accessToken || "");
              await setCodeNavigatorToken(cnAccessToken || "");

              setPostLoader(false);
              // setInitialLeftScrollTrue(false)
              setInitialLeftScrollTrue(true);
              setLeftScrollFlag(true);
            } else {
              localStorage.removeItem("sByCN");
              localStorage.setItem("versionNameId", "");
              // toast.error(
              //   "Oops! Incorrect Address. Going back to the Home Page"
              // );
              // setTimeout(() => {
              //   window.location.href = "/";
              // }, 700);

              // Date: 20 Jan, 2025
              // Ref: MySelf.
              // Changes: Improve Error Handling.
              // toast.error("Data not available in the selected version.");
              setPostLoader(false);
              setEmptyPostLoader(true);
            }
          }
        } else if (data?.message === "Unauthenticated.") {
          setPostLoader(true);

          setAllPosts([]);
          setResToken("");
          setCodeNavigatorToken("");
          setPostLoader(false);
          setInitialLeftScrollTrue(false);
        } else {
          // setPostLoader(true); //important
          setInitialLeftScrollTrue(false);

          setAllPosts([]);
          // toast.error("Unauthorized User !");
          setResToken("");
          setCodeNavigatorToken("");
          // setPostLoader(false);

          // toast.error("Internal error");

          // Date: 20 Jan 2025
          setPostLoader(false);
          setPostLoaderHandler(true);

          // Date: 1 March 2025
          if (data?.message) {
            const errMsg = data?.message ? data.message : "Somthing went wrong";
            setErrMsgForPostLoaderHandler(errMsg);
            toast.error(errMsg); // Date: 17 March 2025
          }
        }
        if (bodyData) {
          setInitialLeftScrollTrue(false);
          setLeftScrollFlag(false);
        } else {
          setInitialLeftScrollTrue(false);
          setLeftScrollFlag(false);
        }

        //  my ORIGINAL LOGIN end
      } catch (err) {
        if (err.message === "Failed to fetch") {
          // toast.error(
          //   "Network error: Unable to fetch data. Please check your connection."
          // );
          setPostLoader(false);
          setFailedToFetchLoaderHandler(true);
        }
        // else{
        //   toast.error(`An error occurred: ${err.message}`)
        // }
        else if (
          err.message === "Cannot read properties of undefined (reading 'slug')"
        ) {
          // toast.error(`An error occurred: ${err.message}`);
          setPostLoader(false);
          setArticleNotMatchedLoaderHandler(true);
        } else {
          toast.error(`An error occurred: ${err.message}`);
          setPostLoader(false);
          setFailedToFetchLoaderHandler(true);
        }
      }
    }
  };

  // useEffect(() => {
  //   if (!hasMounted.current) {
  //     hasMounted.current = true;
  //     return;
  //   }

  //   getAllPostFunc();
  // }, [codeNavigatorToken || resToken]);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    // if (tokenMode || codeNavigatorToken) {
    //   getAllPostFunc();
    // }

    if (isOpenLink) {
      getAllPostFunc();
    } else {
      // if (
      //   tokenMode ||
      //   codeNavigatorToken ||
      //   (versionName.slug && isVersionNameClicked === true)
      // )
      if (tokenMode || codeNavigatorToken) {
        getAllPostFunc();
      }
    }
    // }, [tokenMode, isOpenLink, versionName?.value]);
    // }, [tokenMode, isOpenLink, isVersionNameClicked]);
  }, [tokenMode, isOpenLink]);

  // Date: 1 March 2025
  useEffect(() => {
    if (versionName.slug && isVersionNameClicked) {
      getAllPostFunc();
      // Date: 1 March 2025
      setIsVersionNameClicked(false);
    }
  }, [isVersionNameClicked]);

  // GETTING CN_ACCESS TOKEN FROM REFRESH TOKEN
  useEffect(() => {
    if (allPosts.length > 0) {
      // TOKEN EXPIRATION TIME day | year | HOURS
      const personalVerify = async () => {
        const res = await fetch(`${BaseURL}/${tokenMode}/verify`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${tokenMode === "personal" ? accessToken : cnAccessToken
              }`,
          },
        });
        const resData = await res.json();
        const msg = resData?.message;
        if (msg === "Unauthenticated.") {
          const getCNaccessTokenFromRefreshToken = async () => {
            const rToken = sessionStorage.getItem("cn_rtoken");
            const res = await fetch(
              `${BaseURL}/get-accesstoken-from-refreshtoken`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({ refresh_token: rToken }),
                // body: "",
                // credentials: "include",
              }
            );

            const data = await res.json();

            if (data?.access_token) {
              if (localStorage.getItem("cn_access_token")) {
                localStorage.setItem("cn_access_token", data.access_token);
              } else if (localStorage.getItem("access_token")) {
                localStorage.setItem("access_token", data.access_token);
              }
              sessionStorage.setItem("cn_rtoken", data.refresh_token);
              setCodeNavigatorToken(data.access_token);
              // setCodeNavigatorToken("");
              // setResToken("");

              // Date: 12 Dec, 2024
              // Ref: Email/Meeting on 11 Dec, 2024 from Manigandan to add cn Username and version.
              // Changes: Storing cn Username and version.
              if (data?.customer_name || data?.platform_version) {
                const userInfoFromCN = {
                  customer_name: data.customer_name || "",
                  platform_version: data.platform_version || "",
                };
                localStorage.setItem(
                  "cn_userInfo",
                  JSON.stringify(userInfoFromCN)
                );
                const gettingCnUserInfoData = await GetLocalStorageValue(
                  "cn_userInfo",
                  {}
                );
                setCnUserInfo(gettingCnUserInfoData);
                // setCnUserInfo(userInfoFromCN);
              } else {
                localStorage.removeItem("cn_userInfo");
              }
            }
          };
          getCNaccessTokenFromRefreshToken();
        }
      };
      const interval = setInterval(() => {
        // if (!isOpenLink) {
        personalVerify();
        // }
      }, 120000);
      return () => clearInterval(interval);
    }
    // }, [(accessToken && resToken) || (cnAccessToken && codeNavigatorToken)])
  }, [allPosts.length > 0]);

  // useEffect(() => {
  //   if (allPosts.length > 0) {
  //     // TOKEN EXPIRATION TIME day | year | HOURS
  //     const personalVerify = async () => {
  //       const res = await fetch(`${BaseURL}/${tokenMode}/verify`, {
  //         method: "GET",
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: `Bearer ${
  //             tokenMode === "personal" ? accessToken : cnAccessToken
  //           }`,
  //         },
  //       });
  //       const resData = await res.json();
  //       const msg = resData?.message;
  //       if (msg === "Unauthenticated.") {
  //         localStorage.removeItem("access_token");
  //         localStorage.removeItem("cn_access_token");
  //         setAllPosts([]);
  //         setResToken("");
  //         setCodeNavigatorToken("");
  //         toast.error("Session has been expired !");

  //         const goingLoginFunc = () => {
  //           setResToken("");
  //           setCodeNavigatorToken("");
  //           localStorage.removeItem("access_token");
  //           localStorage.removeItem("cn_access_token");
  //           window.location.reload();
  //         };
  //         return (
  //           <div className="flex items-center justify-center flex-col h-screen px-3 gap-y-4">
  //             {/* <div className={`text-lg`}>404</div> */}
  //             <div className="{`text-lg`}">Session has been expired.</div>
  //             <button
  //               type="submit"
  //               className={`px-11 py-2 rounded-full bg-lighGray text-Black flex items-center gap-1 flex-wrap transition-all font-medium font-Barlow_medium hover:bg-White active:bg-opacity-60`}
  //               onClick={(e) => goingLoginFunc(e)}
  //             >
  //               Go to Login Page
  //             </button>
  //           </div>
  //         );
  //       }
  //     };
  //     const interval = setInterval(() => {
  //       if (!isOpenLink) {
  //         personalVerify();
  //       }
  //     }, 120000);
  //     return () => clearInterval(interval);
  //   }
  //   // }, [(accessToken && resToken) || (cnAccessToken && codeNavigatorToken)])
  // }, [allPosts.length > 0]);

  // console.log("allPosts", allPosts);

  // useEffect(() => {
  //   const myAllPosts = allPosts.flatMap(user => user.posts);
  //   const filtered = myAllPosts.filter(post => post.slug.includes(`${endPath}`));

  //   console.log("filterData",filtered);

  //   // setBodyData(filtered[0]?.body)
  //   // setSelectedHighlight(filtered[0]?.slug)

  // }, [allPosts?.length>0 && endPath]);

  // ctrl + k Keyboard =======
  const handleKeyPress = (event) => {
    if (event.ctrlKey && event.key === "k") {
      event.preventDefault();
      setIsSearchOpen((prevVisible) => !prevVisible);
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const gettingLoginFunc = () => {
    localStorage.removeItem("cn_access_token");
    return (window.location.href = "/");
  };

  const gettingRefreshFunc = () => {
    localStorage.removeItem("cn_access_token");
    return (window.location.href = "/");
  };

  // Date: 8 Feb 2025 start
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  // Disable all things :
  // useEffect(() => {
  //   const handleContextMenu = (event) => event.preventDefault();

  //   const handleKeyDown = (event) => {
  //     // Disable F12, Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U, Ctrl+Shift+C
  //     if (
  //       event.key === "F12" ||
  //       (event.ctrlKey && event.shiftKey && ["I", "J", "C"].includes(event.key)) ||
  //       (event.ctrlKey && event.key === "U")
  //     ) {
  //       event.preventDefault();
  //     }
  //   };

  //   document.addEventListener("contextmenu", handleContextMenu);
  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);
  // Date: 8 Feb 2025 end

  if (postLoader || loginDataLoading) {
    return (
      // <div className="flex items-center justify-center h-screen">Loading..</div>
      <div className="flex items-center justify-center h-screen p-4">
        <div class={`loading`}>
          <span class="letter text-cfBlue">C</span>
          <span class="letter text-cfBlue">l</span>
          <span class="letter text-cfBlue">o</span>
          <span class="letter text-cfBlue">u</span>
          <span class="letter text-cfBlue">d</span>
          <span class="letter">F</span>
          <span class="letter">r</span>
          <span class="letter">a</span>
          <span class="letter">m</span>
          <span class="letter">e</span>
        </div>
      </div>
    );
  }

  // Date: 28 Feb 2025
  const currentArticleAddrGoBackFunc = () => {
    localStorage.removeItem("cn_access_token");
    return (window.location.href = getCurrentArticleAddress);
  };
  // Date: 27 Feb 2025
  if (isArticleNotFound) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        <div>Article not found</div>
        <div
          className={`text-[14px] ${darkTheme ? "text-DarkGray" : "text-LightBlack"
            }`}
        >
          Go back to the home page
        </div>
        {/* <Button onClick={() => gettingRefreshFunc()} className={`!mt-4`}> */}
        <Button
          onClick={() => currentArticleAddrGoBackFunc()}
          className={`!mt-4`}
        >
          Go back
        </Button>
      </div>
    );
  }
  // Date: 27 Feb 2025
  if (isOdArticleNotFound) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        <div>Article not found</div>
        <div
          className={`text-[14px] ${darkTheme ? "text-DarkGray" : "text-LightBlack"
            }`}
        >
          Go back to the home page
        </div>
        <Button onClick={() => gettingRefreshFunc()} className={`!mt-4`}>
          Go back
        </Button>
      </div>
    );
  }
  // IN-OD internal 500 error HANDLER LOADER
  const odRefreshFunc = () => {
    window.location.href = "/od";
  };
  if (odPostLoaderHandler) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        <div>Internal error</div>
        <div
          className={`text-[14px] ${darkTheme ? "text-DarkGray" : "text-LightBlack"
            }`}
        >
          Please try again
        </div>
        <Button onClick={() => odRefreshFunc()} className={`!mt-4`}>
          Refresh
        </Button>
      </div>
    );
  }
  // internal 500 error HANDLER LOADER
  if (postLoaderHandler && accessToken) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        {/* <div>Internal error</div> */}
        {/* Date: 17 March 2025 */}
        <div>{errMsgForPostLoaderHandler}</div>
        <div
          className={`text-[14px] ${darkTheme ? "text-DarkGray" : "text-LightBlack"
            }`}
        >
          {/* Date: 17 March 2025 */}
          Please, refresh the page
        </div>
        <Button onClick={() => gettingRefreshFunc()} className={`!mt-4`}>
          Refresh
        </Button>
      </div>
    );
  }
  // Date: 17 March 2025
  const gotToFallbackVersionFunc = async () => {
    const gettingCnUserInfoData = await GetLocalStorageValue("cn_userInfo", {});
    // setCnUserInfo(gettingCnUserInfoData);
    const fallbackVersion = gettingCnUserInfoData?.platform_version;
    return (window.location.href = `/${fallbackVersion ? fallbackVersion : ""
      }`);
  };
  if (postLoaderHandler && cnAccessToken) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        <div className={`${darkTheme ? "text-Tomato" : "text-Tomato2"}`}>
          Version not available
        </div>
        <div
          className={`text-[14px] ${darkTheme ? "text-DarkGray" : "text-lighGray"
            }`}
        >
          Please, Go Back to Other Version
        </div>
        <Button onClick={() => gotToFallbackVersionFunc()} className={`!mt-4`}>
          Go to other Version
        </Button>
      </div>
    );
  }
  // error HANDLER Fail to Fetch: LOADER
  if (failedToFetchLoaderHandler) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        <div>Can't connect to the network</div>
        <div
          className={`text-[14px] ${darkTheme ? "text-DarkGray" : "text-lighGray"
            }`}
        >
          Please connect to the network
        </div>
        <Button onClick={() => gettingRefreshFunc()} className={`!mt-4`}>
          Refresh
        </Button>
      </div>
    );
  }

  // Date: 15 Feb 2025
  // error HANDLER article not matched: LOADER
  if (articleNotMatchedLoaderHandler) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        <div>Article Not Found</div>
        <div
          className={`text-[14px] ${darkTheme ? "text-DarkGray" : "text-lighGray"
            }`}
        >
          Please, Go back to the home page
        </div>
        {/* <Button onClick={() => gettingRefreshFunc()} className={`!mt-4`}> */}
        <Button
          onClick={() => currentArticleAddrGoBackFunc()}
          className={`!mt-4`}
        >
          Go back
        </Button>
      </div>
    );
  }
  // OD error HANDLER Fail to Fetch: LOADER
  const gettingOdRefreshFunc = () => {
    window.location.href = "/od";
  };
  if (odFailedToFetchLoaderHandler) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        <div>Can't connect to the network</div>
        <div
          className={`text-[14px] ${darkTheme ? "text-DarkGray" : "text-lighGray"
            }`}
        >
          Please connect to the network
        </div>
        <Button onClick={() => gettingOdRefreshFunc()} className={`!mt-4`}>
          Refresh
        </Button>
      </div>
    );
  }

  // OD LOADER
  if (odLoader) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        <div className={`${darkTheme ? "text-Tomato" : "text-Tomato2"}`}>
          Data not available in the selected version
        </div>
        <div
          className={`text-[14px] ${darkTheme ? "text-DarkGray" : "text-lighGray"
            }`}
        >
          Please Login
        </div>
        <Button onClick={() => gettingLoginFunc()} className={`!mt-4`}>
          Login
        </Button>
      </div>
    );
  }
  // empty post LOADER
  const gettingHomePageWithRemoveCNtoken = () => {
    localStorage.removeItem("cn_access_token");
    return (window.location.href = "/");
  };
  if (emptyPostLoader) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        <div className={`${darkTheme ? "text-Tomato" : "text-Tomato2"}`}>
          Data not available in the selected version
        </div>
        <div
          className={`text-[14px] ${darkTheme ? "text-DarkGray" : "text-lighGray"
            }`}
        >
          Please go to home page
        </div>
        <Button
          onClick={() => gettingHomePageWithRemoveCNtoken()}
          className={`!mt-4`}
        >
          Home
        </Button>
      </div>
    );
  }

  // // heading count using DOMparser
  // const parseHTMLAndCountHeadings = (dataArray) => {
  //   return dataArray.map((user) => {
  //     // Map through each user's posts
  //     const updatedPosts = user.posts.map((post) => {
  //       const parser = new DOMParser();
  //       const doc = parser.parseFromString(post.body, "text/html");

  //       // Count all headings h1, h2, h3, and h4
  //       const count = {
  //         h1: doc.getElementsByTagName("h1").length,
  //         h2: doc.getElementsByTagName("h2").length,
  //         h3: doc.getElementsByTagName("h3").length,
  //         h4: doc.getElementsByTagName("h4").length,
  //       };

  //       // Return a new post object with the count key added
  //       return {
  //         ...post,
  //         count,
  //       };
  //     });

  //     // Return a new user object with the updated posts
  //     return {
  //       ...user,
  //       posts: updatedPosts,
  //     };
  //   });
  // };

  // const newUpdatedData = parseHTMLAndCountHeadings(allPosts);

  // console.log("new",newUpdatedData);
  // console.log("old",allPosts);

  return (
    // <div className="max-w-[1440px] mx-auto relative">
    <div className="relative">
      <GlobalStyle darkTheme={darkTheme} />

      <div className="max-w-[1740px] mx-auto ">
        {/* START PROFILE MSAL AUTH  */}

        {/* <AuthenticatedTemplate>
          <ProfileContent />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <h5 className="card-title">
            Please sign-in to see your profile information.
          </h5>
        </UnauthenticatedTemplate> */}

        {isMsalLogin ? (
          <>
            {/* <Router> */}
            <Routes>
              <Route
                path="/authentication"
                element={
                  <AuthenticatedTemplate>
                    <ProfileContent />
                  </AuthenticatedTemplate>
                }
              />

              <Route
                path="/"
                element={
                  <UnauthenticatedTemplate>
                    {/* <h5 className="card-title">
                        Please sign-in to see your profile information.
                      </h5> */}
                    <Login />
                  </UnauthenticatedTemplate>
                }
              />
            </Routes>
            {/* </Router> */}
          </>
        ) : (
          <>
            {/* <Router> */}
            {/* <Layout /> */}

            <Routes>
              {/* login with cn - with - slug */}
              {codeNavigatorSlugLogin === false ? (
                <>
                  {isOpenLink ? (
                    <>
                      <Route path="/" element={<Layout />}>
                        <Route path="/*" element={<Home />} />
                        <Route index element={<Home />} />
                        {/* <Route
                      path="/od/page-not-found"
                      element={<OdPageNotFound />}
                    /> */}
                      </Route>
                    </>
                  ) : (
                    <>
                      {codeNavigatorToken !== "" || resToken !== "" ? (
                        <Route path="/" element={<Layout />}>
                          <Route path="/*" element={<Home />} />
                          <Route index element={<Home />} />
                        </Route>
                      ) : (
                        <>
                          <Route path="/" element={<Login />} />
                          <Route path="/*" element={<PageNotFound />} />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Route
                    // path={endPath ? `/${endPath}/auth` : "/auth"}
                    // path={endPath ? `/${endPath}` : "/auth"}
                    path={`/${endPath}`}
                    element={<Callback />}
                  />
                </>
              )}
              <>
                <Route path={"/auth"} element={<Callback />} />
                {/* Date: 21 Jan 2025  */}
                <Route
                  path={"/version_not_available_with_cn"}
                  element={<CnVersionNotAvailable />}
                />
                {/* Date: 27 Feb 2025  */}
                <Route
                  path="/article-not-available"
                  element={<ArticleNotAvailable />}
                />
              </>
            </Routes>
            {/* </Router> */}
          </>
        )}

        {/* END PROFILE MSAL AUTH  */}
      </div>
    </div>
  );
}
