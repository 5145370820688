import { configureStore } from "@reduxjs/toolkit";
// import CompareArticlesSlice from "./features/CompareArticlesSlice";
import CompareNewArticlesSlice from "./features/CompareNewArticleSlice";
import CompareOldArticleSlice from "./features/CompareOldArticleSlice";

export const store = configureStore({
  reducer: {
    // compareArticles: CompareArticlesSlice,
    compareOldArticle: CompareOldArticleSlice,
    compareNewArticle: CompareNewArticlesSlice,
  },
});