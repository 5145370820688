// Date: 29 Jan 2025
// Ref: meeting for comparison version articles
// Changes: Added new codes to achieve compare version articles.
import React, { useContext, useEffect, useRef, useState } from "react";
import DiffMatchPatch from "diff-match-patch";
import { UserContext } from "../../UserContext";
import { BaseURL } from "../../BaseURL";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchNewArticle,
//   newEmptyCompArticle,
//   oldEmptyCompArticle,
// } from "../../Redux/features/CompareNewArticlesSlice";
import {
  fetchOldArticle,
  oldEmptyCompArticle,
} from "../../Redux/features/CompareOldArticleSlice";
import {
  fetchNewArticle,
  newEmptyCompArticle,
} from "../../Redux/features/CompareNewArticleSlice";

import { parse, stringify } from "html-parse-stringify2";
import { html } from "diffhtml";
import Button from "../../reusable/Button";

export const SideBySideVersionComp = ({
  // setIsVersionSelected,
  compareOldVersionName,
  compareNewVersionName,
  setCompareOldVersionName,
  setCompareNewVersionName,
  // setCompareVersionLoader,
  // compareVersionLoader,
  // loadBodyData,
}) => {
  const { bodyData, selectedHighlight, tokenMode, darkTheme, versionName } =
    useContext(UserContext);
  const dispatch = useDispatch();

  // Date: 19 Feb 2025
  const { oldArticle, isLoadingOldArticle, errorOldArticle } = useSelector(
    (state) => state.compareOldArticle
  );
  const { newArticle, isLoadingNewArticle, errorNewArticle } = useSelector(
    (state) => state.compareNewArticle
  );
  // const [diffResults, setDiffResults] = useState([]);
  const [diffResult, setDiffResult] = useState("");
  const [showDiff, setShowDiff] = useState(true);
  const [isSame, setIsSame] = useState(false); // State to track if articles are identical
  const [highlightedNewArticle, setHighlightedNewArticle] = useState("");

  // ACCESS TOKEN
  // const accessToken = localStorage.getItem("access_token");
  // // CN ACCESS TOKEN
  // const cnAccessToken = localStorage.getItem("cn_access_token");

  const hasMounted = useRef(false);

  // useEffect(() => {
  //   if (!hasMounted.current) {
  //     hasMounted.current = true;
  //     return;
  //   }
  //   // fetchArticles(oldArticle, newArticle);
  //   fetchArticles();
  // }, [oldArticle, newArticle]);
  // useEffect(() => {
  //   dispatch(oldEmptyCompArticle());
  //   dispatch(newEmptyCompArticle());
  //   setCompareOldVersionName("");
  //   setCompareNewVersionName("");
  // }, []);

  // 15 Feb 2025
  useEffect(() => {
    // dispatch(newEmptyCompArticle());
    const currentSlug = versionName.slug;
    dispatch(
      fetchNewArticle({
        versionSlug: currentSlug,
        tokenMode,
        selectedHighlight,
      })
    );

    setCompareNewVersionName(currentSlug);
  }, []);

  const dmp = new DiffMatchPatch();

  const highlightDifferences = (originalHtml, modifiedHtml) => {
    const originalAst = parse(originalHtml);
    const modifiedAst = parse(modifiedHtml);

    const compareNodes = (originalNodes, modifiedNodes, isOriginal = false) => {
      let result = isOriginal ? [...originalNodes] : [...modifiedNodes];

      originalNodes.forEach((origNode, index) => {
        const modNode = modifiedNodes[index];

        if (!modNode) {
          // Deleted content (Red in Original)
          result[index] = {
            ...origNode,
            name: "del",
            attrs: {
              style: `background-color: ${
                darkTheme ? "#4f000b" : "#ffccd5"
              } !important; text-decoration: none !important;`,
            },
            children: origNode.children || [],
          };
        } else if (origNode.name === modNode.name) {
          // Compare only text content, ignore identical structure
          if (
            origNode.content &&
            modNode.content &&
            origNode.content !== modNode.content
          ) {
            const diffs = dmp.diff_main(origNode.content, modNode.content);
            dmp.diff_cleanupSemantic(diffs);

            let diffContent = "";
            diffs.forEach(([type, text]) => {
              if (type === -1) {
                diffContent += `<del style="background-color:${
                  darkTheme ? "#4f000b" : "#ffccd5"
                } !important;">${text}</del>`; // Red in Original
              } else if (type === 1) {
                diffContent += `<ins style="background-color:${
                  darkTheme ? "#31572c" : "#d8f3dc"
                } !important;">${text}</ins>`; // Green in Modified
              } else {
                diffContent += text;
              }
            });

            result[index] = { ...origNode, content: diffContent };
          } else if (origNode.children && modNode.children) {
            // Recursive comparison for nested elements
            result[index].children = compareNodes(
              origNode.children,
              modNode.children,
              isOriginal
            );
          }
        }
      });

      return result;
    };

    const diffOriginal = compareNodes(originalAst, modifiedAst, true); // Highlighted Original
    const diffModified = compareNodes(originalAst, modifiedAst, false); // Highlighted Modified

    return {
      highlightedOriginal: stringify(diffOriginal),
      highlightedModified: stringify(diffModified),
    };
  };

  const { highlightedOriginal, highlightedModified } = highlightDifferences(
    oldArticle,
    newArticle
  );

  // if (isLoading) {
  //   return (
  //     <p
  //       className={`p-4 rounded-lg ${
  //         darkTheme ? "bg-[#2626264f]" : "bg-[#ffffff48]"
  //       }`}
  //     >
  //       Please wait..
  //     </p>
  //   );
  // }
  // if (error) {
  //   return (
  //     <p
  //       className={`p-4 rounded-lg ${
  //         darkTheme
  //           ? "text-Tomato bg-[#2626264f]"
  //           : "text-Tomato2 bg-[#ffffff48]"
  //       }`}
  //     >
  //       Error: {error}
  //     </p>
  //   );
  // }

  // Date: 25 Jan 2025
  // Ref: By meeting to achieve compare between two articles.
  // Changes: Added new codes to achieve functionality.
  if (oldArticle === "" && newArticle === "") {
    return (
      <p
        className={`p-4 rounded-lg text-center ${
          darkTheme ? "bg-[#2626264f]" : "bg-[#ffffff48]"
        }`}
      >
        {/* Date: 15 Feb 2025 */}
        {/* Please Select Version to Compare */}
        {/* Date: 19 Feb 2025  */}
        Please wait..
      </p>
    );
  }
  // else if (
  //   (oldArticle && newArticle === "") ||
  //   (newArticle && oldArticle === "")
  // ) {
  //   return (
  //     <p
  //       className={`p-4 rounded-lg ${
  //         darkTheme ? "bg-[#2626264f]" : "bg-[#ffffff48]"
  //       }`}
  //     >
  //       Please select both versions for comparison
  //     </p>
  //   );
  // }

  return (
    <>
      {/* {isSame ? (
        <div
          className={`p-4 rounded-lg ${
            darkTheme
              ? "text-Tomato bg-[#2626264f]"
              : "text-Tomato2 bg-[#ffffff48]"
          }`}
        >
          Both articles are the same
        </div>
      ) : ( */}
      <div
        className={`flex border rounded-lg ${
          darkTheme ? "border-[#6d6f72]" : "border-[#c1cecf]"
        }`}
      >
        {/* Old Article */}
        <div
          className={`w-[50%] p-2 rounded-tl-lg rounded-bl-lg overflow-auto ${
            darkTheme
              ? "bg-[#2626264f] scrollBar-dark"
              : "bg-[#ffffff48] scrollBar"
          }`}
        >
          {/* <div className={`text-center font-medium pb-3`}>
              {selectedHighlight ? selectedHighlight : ""} (
              {compareOldVersionName.slug
                ? `v${compareOldVersionName.slug}`
                : "Version"}
              )
            </div> */}
          {/* <div
              dangerouslySetInnerHTML={{ __html: oldArticle }}
              style={{ whiteSpace: "pre-wrap" }}
            /> */}

          {/* Date: 15 Feb 2025 */}
          {
            // oldArticle === "" ? (
            //   <div className="text-center mt-4">
            //     {/* Date: 15 Feb 2025 */}
            //     Please Select Version to Compare
            //   </div>
            // ) :

            // Date: 19 Feb 2025
            isLoadingOldArticle ? (
              <p
                className={`p-4 rounded-lg text-center ${
                  darkTheme ? "bg-[#2626264f]" : "bg-[#ffffff48]"
                }`}
              >
                Please wait..
              </p>
            ) : errorOldArticle ? (
              <p
                className={`p-4 rounded-lg text-center ${
                  darkTheme
                    ? "text-Tomato bg-[#2626264f]"
                    : "text-Tomato2 bg-[#ffffff48]"
                }`}
              >
                Error: {errorOldArticle}
              </p>
            ) : oldArticle === "" ? (
              <div className="text-center mt-4">
                {/* Date: 15 Feb 2025 */}
                Please Select Version to Compare
              </div>
            ) : (
              <>
                <div className={`text-center pb-3`}>
                  <div className={`font-medium`}>
                    {selectedHighlight ? selectedHighlight : ""} (
                    {compareOldVersionName.slug
                      ? `v${compareOldVersionName.slug}`
                      : "Version"}
                    )
                  </div>
                  <div>
                    {/* Toggle Button */}
                    <Button
                      className={`!text-[14px] !rounded-full`}
                      onClick={() => setShowDiff(!showDiff)}
                    >
                      {showDiff ? "Show Raw View" : "Show Highlighted Diff"}
                    </Button>
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: showDiff ? highlightedOriginal : oldArticle,
                  }}
                  style={{ whiteSpace: "pre-wrap" }}
                />
              </>
            )
          }
        </div>

        {/* New Article */}
        <div
          className={`w-[50%] border-l p-2 rounded-tr-lg rounded-br-lg overflow-auto ${
            darkTheme
              ? "border-[#6d6f72] bg-[#2626264f] scrollBar-dark"
              : "border-[#c1cecf] bg-[#ffffff48] scrollBar"
          }`}
        >
          {/* Date: 19 Feb 2025 */}
          {isLoadingNewArticle ? (
            <p
              className={`p-4 rounded-lg text-center ${
                darkTheme ? "bg-[#2626264f]" : "bg-[#ffffff48]"
              }`}
            >
              Please wait..
            </p>
          ) : errorNewArticle ? (
            <p
              className={`p-4 rounded-lg text-center ${
                darkTheme
                  ? "text-Tomato bg-[#2626264f]"
                  : "text-Tomato2 bg-[#ffffff48]"
              }`}
            >
              Error: {errorNewArticle}
            </p>
          ) : newArticle === "" ? (
            <div className="text-center mt-4">
              {/* Date: 15 Feb 2025 */}
              Please Select Version to Compare
            </div>
          ) : (
            <>
              <div className={`text-center pb-3`}>
                <div className={`font-medium`}>
                  {selectedHighlight ? selectedHighlight : ""} (
                  {compareNewVersionName.slug
                    ? `v${compareNewVersionName.slug}`
                    : "Version"}
                  )
                </div>
                <div>
                  {/* Toggle Button */}
                  <Button
                    className={`!text-[14px] !rounded-full`}
                    onClick={() => setShowDiff(!showDiff)}
                  >
                    {showDiff ? "Show Raw View" : "Show Highlighted Diff"}
                  </Button>
                </div>
              </div>
              <div
                // dangerouslySetInnerHTML={{ __html: diffResult }}
                dangerouslySetInnerHTML={{
                  __html: showDiff ? highlightedModified : newArticle,
                }}
                style={{ whiteSpace: "pre-wrap" }}
              />
            </>
          )}
        </div>
      </div>
      {/* )} */}
    </>
  );
};
