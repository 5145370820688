import React, { useContext } from "react";
import { motion } from "framer-motion";
import { UserContext } from "../../UserContext";
import { BaseURL } from "../../BaseURL";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { HiOutlineHandThumbUp } from "react-icons/hi2";
import { HiOutlineHandThumbDown } from "react-icons/hi2";
import { Tooltip } from "react-tippy";
import { HiHandThumbDown } from "react-icons/hi2";
import { HiHandThumbUp } from "react-icons/hi2";
import GetLocalStorageValue from "../../reusable/GetLocalStorageValue";

export default function FeedbackModal({ subTitle, feedbackModalToggle }) {
  const { darkTheme, userLoginInfo, postId, resToken } = useContext(UserContext);
  const access_token = localStorage.getItem("access_token");
  const [comment, setComment] = useState("");
  const [like, setLike] = useState(0);
  const [dislike, setDislike] = useState(0);
  const [submitDisable, setSubmitDisable] = useState(false);

  // Date: 29 March 2025 
  // ACCESS TOKEN
  const accessToken = localStorage.getItem("access_token");
  // CN ACCESS TOKEN
  const cnAccessToken = localStorage.getItem("cn_access_token");
  const gettingCnUserInfoData = GetLocalStorageValue(
    "cn_userInfo",
    {}
  );

  const feedbackFunc = async (e) => {
    e.preventDefault();

    const likeDislike = like === 1 ? like : dislike === 1 ? 0 : "empty";
    if (!comment) {
      toast.error("Enter comment");
      return;
    }
    if (likeDislike === "empty") {
      toast.error("Please press the Like or Dislike icon");
      return;
    }
    setSubmitDisable(true);

    // const formData = {
    //   user_id: userLoginInfo.user_id,
    //   post_id: postId,
    //   // feedback: feedback,
    //   feedback: likeDislike,
    //   comment: comment,
    // };
    // const res = await fetch(`${BaseURL}/codenavigator/posts/feedback`, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     // Authorization: `Bearer ${access_token}`,
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(formData),
    // });
    // Date: 29 March 2025 
    // Ref: "As Per Manigandan"
    // Changes: "Separete two endpoints for codenavigator feedback and personal user feedback."
    const formData = resToken ? {
      user_id: userLoginInfo.user_id,
      post_id: postId,
      feedback: likeDislike,
      comment: comment,
    } : {
      post_id: postId,
      feedback: likeDislike,
      comment: comment,
    };

    const res = await fetch(`${BaseURL}/${resToken ? "personal" : "codenavigator"}/posts/feedback`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${access_token}`,
        Authorization: `Bearer ${resToken ? accessToken : cnAccessToken
          }`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const data = await res.json();
    if (data?.status) {
      // toast.success(
      //   `${userLoginInfo?.user_name ? userLoginInfo.user_name: ""} Your feedback has been successfully sent`
      // );
      toast.success(
        `${userLoginInfo?.user_name ? userLoginInfo.user_name : gettingCnUserInfoData?.customer_name ? gettingCnUserInfoData.customer_name : ""} Your feedback has been successfully sent`
      );

      setTimeout(() => {

        window.location.reload();
      }, 1500)

      setComment("");
      setLike(0);
      setDislike(0);
      setSubmitDisable(false);
      feedbackModalToggle();
    } else {
      feedbackModalToggle();
      toast.error("You have already given feedback on this article");
      setSubmitDisable(false);
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 1, y: 25 }}
      transition={{ delay: 0.2, type: "easeInOut", duration: 0.5 }}
      className={`relative mx-4 w-full md:w-[600px] rounded-xl p-2 md:p-4 shadow ${darkTheme ? "bg-DarkGray" : "bg-Bg"
        }`}
      onClick={(e) => e.stopPropagation()}
    >
      <form>
        <div>
          <div className={`text-lg md:text-lg lg:text-xl pb-2`}>
            Rate this content
          </div>
          <div className={`flex items-center gap-6`}>
            <div>
              <Tooltip
                title="Positive Feedback"
                position="top"
                trigger="mouseenter"
                // theme={darkTheme? "dark": "light"}
                size="regular"
                delay={500}
              >
                {like === 1 ? (
                  <HiHandThumbUp
                    className={`w-5 h-5 ${like === 1 &&
                      (darkTheme ? "text-[#1ec51e]" : "text-[#1ec51e]")
                      }`}
                    onClick={(e) => {
                      setLike(1);
                      setDislike(0);
                    }}
                  />
                ) : (
                  <HiOutlineHandThumbUp
                    className={`w-5 h-5`}
                    onClick={(e) => {
                      setLike(1);
                      setDislike(0);
                    }}
                  />
                )}
              </Tooltip>
            </div>
            <div>
              <Tooltip
                // title="Was this topic helpful?"
                title="Negative Feedback"
                position="top"
                trigger="mouseenter"
                // theme={darkTheme? "dark": "light"}
                size="regular"
                delay={500}
              >
                {dislike === 1 ? (
                  <HiHandThumbDown
                    className={`w-5 h-5 ${dislike === 1 &&
                      (darkTheme ? "text-[#ec4949]" : "text-[#d62424]")
                      }`}
                    onClick={(e) => {
                      setLike(0);
                      setDislike(1);
                    }}
                  />
                ) : (
                  <HiOutlineHandThumbDown
                    className={`w-5 h-5`}
                    onClick={(e) => {
                      setLike(0);
                      setDislike(1);
                    }}
                  />
                )}
              </Tooltip>
            </div>
          </div>
          <div className={`py-2`}>{subTitle}</div>

          <div className={`flex items-start gap-1 flex-col`}>
            <label className={`text-sm`}>Comment {comment.length}/420</label>
            <textarea
              className={`py-1 px-2 md:px-3 rounded-xl w-full ${darkTheme
                ? "bg-LightBlack outline-Blue4"
                : "bg-Border outline-Orange"
                }`}
              rows="3"
              maxLength={"420"}
              placeholder="Maximum of 420 characters"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>

          <div className={`text-sm text-justify mt-4`}>
            <b>Note:</b> This feedback goes to product's documentation team and
            does not include a response. Issues that require a response should
            go through CloudFrame support.
          </div>

          <div className={`flex items-start gap-4 mt-4`}>
            <button
              className={`py-1 px-4 rounded-xl transition-all ${darkTheme
                ? "bg-Icon active:bg-gray hover:bg-LightBlack"
                : "bg-Border active:bg-White hover:bg-Hover"
                }`}
              onClick={() => feedbackModalToggle()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`py-1 px-4 rounded-xl transition-all ${darkTheme
                ? comment.length >= 3
                  ? "bg-Blue4"
                  : "bg-Icon active:bg-gray hover:bg-LightBlack"
                : comment.length >= 3
                  ? "bg-Orange"
                  : "bg-Border active:bg-White hover:bg-Hover"
                }
              }`}
              onClick={(e) => feedbackFunc(e)}
              disabled={submitDisable}
            >
              {submitDisable ? "Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </motion.div>
  );
}
