import React, { useContext, useEffect, useRef, useState } from "react";
import LeftHome from "./slices/LeftHome";
import MiddleHome from "./slices/MiddleHome";
import RightHome from "./slices/RightHome";
import TopMenuHeader from "./slices/TopMenuHeader";
import { UserContext } from "../UserContext";
import Search from "./slices/Search";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import { AnimatePresence, motion } from "framer-motion";
import FeedbackModal from "./slices/FeedbackModal";
import { useLayoutEffect } from "react";
import { Tooltip } from "react-tippy";
import FooterLogo from "../../../src/img/logo-white-blue.webp";
import { GoCopy } from "react-icons/go";

// files icons
import pdfImg from "../../img/icons/pdf-icon.png";
import zipImg from "../../img/icons/zip-icon.png";
import xlsxImg from "../../img/icons/xlsx-icon.png";
import csvImg from "../../img/icons/csv-icon.png";
import txtImg from "../../img/icons/txt-icon.png";
import javaImg from "../../img/icons/java-icon.png";
import { BaseURL } from "../BaseURL";
import ArticleNotFoundModal from "./slices/ArticleNotFoundModal";
import ArticleNotFoundByVersionModal from "./slices/ArticleNotFoundByVersionModal";

export default function Home() {
  const {
    isSmMenuOpen,
    setIsSmMenuOpen,
    isSearchOpen,
    setIsSearchOpen,
    darkTheme,
    bodyData,
    setBodyData,
    selectedHighlight,
    setSelectedHighlight,
    setResToken,
    setAllPosts,
    allPosts,
    setCodeNavigatorToken,
    setOpenMenu,
    openMenu,
    breadCrumb,
    setBreadCrumb,
    breadCrumbCategory,
    setBreadCrumbCategory,
    urlTagIdStore,
    setUrlTagIdStore,
    initialLeftScrollTrue,
    setInitialLeftScrollTrue,
    leftScrollFlag,
    setLeftScrollFlag,
    setPostId,
    isOpenLink,
    tokenMode,
    isFooterVisible,
    setAllowFeedback,
    versionName,
    versionNameInitialSlugSet,
    userLoginInfo, // 28 Jan 2025
    // 7 Feb 2025
    removeGrid,
    setRemoveGrid,
    leftBarHide,
    setleftBarHide,
    iconContentTitle,
    setIconContentTitle,
    collapseLeftBarFun,
    setIsVersionArticleNotFound,
    isVersionArticleNotFound,
    setStoreClickedVersion,
  } = useContext(UserContext);

  // ACCESS TOKEN
  const accessToken = localStorage.getItem("access_token");

  // CN ACCESS TOKEN
  const cnAccessToken = localStorage.getItem("cn_access_token");

  // const [openMenu, setOpenMenu] = useState(null);

  // Date: 28 Feb 2025
  const [isArticleNotFound, setIsArticleNotFound] = useState(false);

  const [subOpenMenu, setSubOpenMenu] = useState(null);
  const [searchInp, setSearchInp] = useState("");
  // const [bodyData, setBodyData] = useState({});
  const [pageHeadings, setPageHeadings] = useState([]);
  // const [selectedHighlight, setSelectedHighlight] = useState(null)
  const navigate = useNavigate();
  const [isPrevBtn, setIsPrevBtn] = useState(false);
  const [isParentHeadingClicked, setIsParentHeadingClicked] = useState("");

  // current footer date
  const [currentYear, setCurrentYear] = useState("");
  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    setCurrentYear(year);
  }, []);

  // Date: 28 Feb 2025
  const checkingArticleDataFunc = async (addr) => {
    // const endPath = addr.split("/").pop();
    const cleanUrl = addr.split("#")[0];
    const parsedUrl = new URL(cleanUrl);
    const endPath2 = parsedUrl.pathname.slice(1);

    const versionRegex = /\b\d{1,3}(\.\d{1,3}){1,3}\b/; // best math[0] not match[1]

    // const versionMatch = addr.match(versionRegex);
    const versionMatch = endPath2.match(versionRegex);
    const endPath = endPath2.replace(versionRegex, "").replace(/^\/|\/$/g, "");

    let version = null;
    if (versionMatch) {
      // version = versionMatch[1]; // Extract '2.0.0' from 'version2.0.0'
      version = versionMatch[0];
    }

    const versionData = {
      version: version ? version : "",
    };
    const queryParams = new URLSearchParams(versionData).toString();
    const versionValidate = queryParams ? queryParams : "";

    try {
      const res = await fetch(
        `${BaseURL}/${tokenMode}/posts?${versionValidate}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${
              tokenMode === "personal" ? accessToken : cnAccessToken
            }`,
          },
        }
      );
      const data = await res.json();

      if (res.ok && data?.success) {
        const myAllPosts = await data.data.flatMap(
          (category) => category.posts
        );

        // CHECKING ARTICLE
        const filtered = await myAllPosts.filter(
          (post) => post.slug === endPath
        );
        if (filtered.length > 0) {
          window.location.href = addr;
        } else {
          // toast.error("Article not found");
          setIsArticleNotFound(true);
        }
      } else {
        toast.error(`Error: ${res.status} ${res.statusText}`);
      }
    } catch (err) {
      if (err.message === "Failed to fetch") {
        toast.error("Failed to fetch");
      } else {
        toast.error(err.message);
      }
    }
  };
  const stopCheckLinkClickFunc = (event, link) => {
    event.preventDefault();
    toast("Please wait, it's processing..");
    checkingArticleDataFunc(link);
  };

  useEffect(() => {
    if (selectedHighlight) {
      if (isOpenLink) {
        navigate(`${isOpenLink}/${selectedHighlight}`, { replace: true });
      } else {
        if (cnAccessToken || accessToken) {
          navigate(
            `${
              versionName?.slug ? versionName.slug : versionNameInitialSlugSet
            }/${selectedHighlight}`,
            { replace: true }
          );
        } else {
          localStorage.removeItem("access_token");
          localStorage.removeItem("cn_access_token");
          setAllPosts([]);
          setResToken("");
          setCodeNavigatorToken("");
          toast.error("Session has been expired !");
          navigate("/");
        }
      }
    }
  }, [selectedHighlight, bodyData]);

  const toggleDropdown = (index, getData) => {
    // setElementsContent([]);

    // setOpenMenu(openMenu === index ? null : index);
    setBodyData(openMenu === index ? {} : getData);
    setIsSmMenuOpen(getData?.items?.length > 0 ? true : false);
    setSelectedHighlight(null);
  };

  const subToggleDropdown = (title, getData, index, categoryName) => {
    // if (getData?.slug !== selectedHighlight) {
    // setElementsContent([]);
    // setBodyData({});

    setSelectedHighlight(null);
    setBreadCrumb(getData);

    setSelectedHighlight(getData.slug);
    setBreadCrumbCategory(categoryName);
    setBreadCrumb(getData.title);
    setPostId(getData.id);
    setAllowFeedback(getData.post_feedbacks_count);

    setOpenMenu(openMenu === getData.id ? null : getData.id);
    setBodyData(getData);
    setPageHeadings(getData);
    setIsSmMenuOpen(false);
    setIsSearchOpen(false);
    setSearchInp("");

    setIsPrevBtn(true);

    // PreviousBtn Ref setUp

    // } else {
    //   setIsSmMenuOpen(false);

    //   setIsSearchOpen(false);
    // }
  };

  const SearchToggle = () => {
    setIsSearchOpen(false);
    setSearchInp("");
  };

  // Middle page scroll to top
  // const scrollToTop = () => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollTo({ top: 0, behavior: 'smooth' }); // Set scroll position to the top
  //   }
  // };

  // RIGHT PAGE SET-UP ========KPK
  const containerRefWrapper = useRef(null);
  const containerRef = useRef(null);
  const sidebarRef = useRef(null);
  const [headings, setHeadings] = useState([]);
  const [activeHeadingId, setActiveHeadingId] = useState(null);
  const [activeElement, setActiveElement] = useState(null);
  const [isActiveElement, setIsActiveElement] = useState(false);
  const [activeElementId, setActiveElementId] = useState(null);
  const [expandedHeadings, setExpandedHeadings] = useState({});

  // TOP SCROLL WHEN bodyData UPDATED
  const addStringToUrl = () => {
    // Construct the new URL with the existing path and the appended string
    const newUrl = `${window.location.origin}${window.location.pathname}#${urlTagIdStore}`;

    // Update the URL without reloading the page
    // window.history.pushState({ path: newUrl }, '', newUrl);
    window.history.replaceState({ path: newUrl }, "", newUrl);
    // navigate(`${newUrl}`, { replace: false });
    // console.log("newwwUrl", window.history.replaceState({ path: newUrl }, '', newUrl))
  };

  // MIDDLE PAGE SCROLLING TO TOP
  useEffect(() => {
    if (urlTagIdStore === "") {
      if (containerRefWrapper.current) {
        containerRefWrapper.current.scrollTo({ top: 0, behavior: "instant" });
        // containerRefWrapper.current.scrollTo({ top: 0 });
      }
    } else {
      // URL ID SCROLL FUNCTIONALITY
      const contentEl = containerRef.current;
      if (urlTagIdStore) {
        const idUrlEl = contentEl.querySelector(`#${urlTagIdStore}`) || "";
        // console.log("idurl", idUrlEl)
        if (idUrlEl) {
          // idUrlEl.scrollIntoView({ behavior: "smooth" });
          // Date: 11 Dec, 2024
          // Ref: We found some scrolling effect issue
          // Changes: Added new scroll effect
          // idUrlEl.scrollIntoView({ behavior: "smooth", block: "start" });

          // Date: 4 Feb, 2025
          // Ref: As per Manigandan, Issue is not fixed when jump another article using id link that time heading are hide in header behind. We are testing in only after deployment.
          // Changes: Added new codes to avoide hiding of content in header after scoll, in testing that is not fixed.
          // idUrlEl.scrollIntoView({
          //   behavior: "instant",
          //   block: "start",
          //   top: 140,
          // });

          // Date: 7 Feb, 2025
          // Ref: As per Manigandan, Issue is fixed when jump another article using id link that time heading are hide in header behind. We are testing in only after deployment.
          // Changes: Added new codes to avoide hiding of content in header after scoll, in testing that is resolved.
          idUrlEl.scrollIntoView({
            behavior: "instant",
            block: "center",
            top: 135,
          });
          // WITHOUR RELOADING PAGE URL REPLACE
          // addStringToUrl();

          setUrlTagIdStore("");
        }
      }
    }

    // GAP IMPLEMENTATION END OF ARTICLE
    // date: 30 nov 2024 as per Manigandan

    if (!containerRef.current || !containerRef.current.innerHTML.trim()) {
      // console.warn('No content in the container.');
      toast.error("No content in the container.");
      return;
    }

    // const appendToLastTag = () => {
    if (containerRef.current) {
      // Find the last element in the container
      // const allElements = containerRef.current.querySelectorAll("*");
      const lastElement = document.getElementById("middlePage");
      const spacerDiv = document.getElementById("spacerDiv");
      // const lastElement = allElements[allElements.length - 1];
      if (lastElement && !spacerDiv) {
        const divElem = document.createElement("div");
        // divElem.style = "padding-bottom:140px; background: yellow;";
        // divElem.style = "margin-bottom:140px;";
        // divElem.textContent = "hello container";
        // lastElement.appendChild(divElem);
        divElem.id = "spacerDiv";
        divElem.appendChild(document.createElement("br"));
        divElem.appendChild(document.createElement("br"));
        divElem.appendChild(document.createElement("br"));
        lastElement.appendChild(divElem);
        // lastElement.insertAdjacentElement('afterend', divElem);
      }
    }
    // };
    // setTimeout(appendToLastTag, 0);
  }, [bodyData]);

  // Build hierarchical structure for the sidebar
  const buildHierarchy = (headings) => {
    const hierarchy = [];
    let currentH1 = null;
    let currentH2 = null;
    let currentH3 = null;

    headings.forEach((heading) => {
      const uniqueId = `${Date.now()}-${Math.floor(Math.random() * 1000000)}`;

      // console.log(uniqueId); // e.g., "1635112345678-234789"
      const { tagName, innerText, id } = heading;
      // heading.id =id || `${tagName}-${innerText.replace(/\s+/g, uniqueId).toLowerCase()}`; // more unique
      // heading.id =  id || `${tagName}-${innerText.replace(/\s+/g, "-").toLowerCase()}`;

      // Date: 7 Jan, 2025
      // Ref: Sent a whatsapp message by Manigandan for some bux of heading when hover then we saw some unique number.
      // Changes: Added new codes.
      heading.setAttribute("kpk", uniqueId);
      // const gettingAttrKPK= heading.getAttribute("kpk");

      // Date: 23 Jan 2025
      // heading.id = id || innerText.toLowerCase().replace(/ /g, "");

      if (tagName === "H1") {
        currentH1 = {
          tagName,
          text: innerText,
          children: [],
          kpk: uniqueId,
          // id: heading.id, // Date: 23 Jan 2025
          dropdownLevelClass: "dropdownLevel-0",
          dropdownlevel: 0,
        };
        hierarchy.push(currentH1);
        currentH2 = null;
        currentH3 = null;
      } else if (tagName === "H2" && currentH1) {
        currentH2 = {
          tagName,
          text: innerText,
          children: [],
          kpk: uniqueId,
          // id: heading.id,
          dropdownLevelClass: "dropdownLevel-1",
          dropdownlevel: 1,
        };
        currentH1.children.push(currentH2);
        currentH3 = null;
      } else if (tagName === "H3" && currentH2) {
        currentH3 = {
          tagName,
          text: innerText,
          children: [],
          kpk: uniqueId,
          // id: heading.id,
          dropdownLevelClass: "dropdownLevel-2",
          dropdownlevel: 2,
        };
        currentH2.children.push(currentH3);
      } else if (tagName === "H4" && currentH3) {
        currentH3.children.push({
          tagName,
          text: innerText,
          kpk: uniqueId,
          // id: heading.id,
          dropdownLevelClass: "dropdownLevel-3",
          dropdownlevel: 3,
        });
      }

      // Date: 11 Dec, 2024
      // Ref: I improved scroll effect for auto highlight when scrolling and clicked heading scroll, for original code please go to filename: Home copy 6.
      // Changes: Remove Unnecessary codes
    });
    return hierarchy;
  };

  // Extract headings and build a hierarchical structure
  useEffect(() => {
    const contentEl = containerRef.current;

    if (contentEl) {
      const allHeadings = Array.from(
        contentEl.querySelectorAll("h1, h2, h3, h4")
      );

      // Build the hierarchy and set it in the state
      const structuredHeadings = buildHierarchy(allHeadings);
      setHeadings(structuredHeadings);
    }

    // Date: 11 Dec, 2024
    // Ref: I improved scroll effect for auto highlight when scrolling and clicked heading scroll, for original code please go to filename: Home copy 6.
    // Changes: Remove Unnecessary codes
  }, [bodyData]);

  // Use IntersectionObserver to track visible headings
  // Date: 11 Dec, 2024
  // Ref: I improved scroll effect for auto highlight when scrolling and clicked heading scroll, for original code please go to filename: Home copy 6.
  // Changes: Added codes.
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const gettingAttrKPK = entry.target.getAttribute("kpk");
            setActiveHeadingId(gettingAttrKPK); // Update active heading

            // const sidePHeading = document.querySelector(
            const sidePHeading = document.querySelector(
              `[identity="${gettingAttrKPK}"]`
            );
            if (
              sidePHeading &&
              isParentHeadingClicked !== `${gettingAttrKPK}`
            ) {
              // const getIdentityAttr = sidePHeading.getAttribute("identity");
              const getIdentityAttr = sidePHeading.getAttribute("iconidentity");
              if (isParentHeadingClicked !== gettingAttrKPK) {
                // if (
                //   (isParentHeadingClicked !==
                //     `${entry.target.title}`) ||
                //   !isParentHeadingClicked
                // ) {
                // sidePHeading.removeAttribute("style");
                // sidePHeading.click();
                setIsParentHeading(gettingAttrKPK);

                // sidePHeading.style.border="2px solid orange !important";
                // sidePHeading.classList.add("parentHeadingClass");

                // Close if another parent Heading open START
                // if(isParentHeading){

                //   const level = parseInt(
                //     isParentHeading.getAttribute("dropdownlevel")
                //   );
                //   const parentLevel = level - 1;
                //   if (parentLevel >= 0) {
                //     const parentLevelClass = ".dropdownLevel-" + parentLevel;

                //     const prevParentHeadingClickable = isParentHeading
                //       .closest(parentLevelClass)
                //       .querySelector(parentLevelClass);

                //       prevParentHeadingClickable.click();
                //   }
                // }
                // Close if another parent Heading open END
              }
            }
            // scrollToHeading(entry.target.title);
            highlightParentElement(`${gettingAttrKPK}`);

            // console.log("t==>", entry.target.title);
          }
        });
      },
      {
        // root: containerRef.current,
        rootMargin: "0px 0px -60% 0px", // Adjust margins for visibility
        threshold: 0.1,
      } // Adjust threshold as needed
    );

    const middlePageHeadings =
      containerRef.current?.querySelectorAll("h1, h2, h3, h4");
    middlePageHeadings?.forEach((heading) => observer.observe(heading));

    return () => {
      middlePageHeadings?.forEach((heading) => observer.unobserve(heading));
    };
  }, [bodyData]);

  // 27 Jan 2025
  // const hasMounted = useRef(false);
  useEffect(() => {
    // if (!hasMounted.current) {
    //   hasMounted.current = true;
    //   return;
    // }

    if (userLoginInfo.can_view_heading_id === 1) {
      const handleCopy = (e, text) => {
        const copyElem = e.target;
        navigator.clipboard.writeText(text).then(
          () => {
            // toast.success("Copied to clipboard!");
            copyElem.innerHTML = "Copied!";
            setTimeout(() => {
              copyElem.innerHTML = "Copy";
            }, 1500);
          },
          (err) => {
            console.error("Failed to copy text: ", err);
          }
        );
      };
      const headings = containerRef.current?.querySelectorAll(
        "h1, h2, h3, h4, h5, h6"
      );
      headings.length > 0 &&
        headings.forEach((heading) => {
          const getHeadingId = heading.getAttribute("id");
          // const addCopyId = document.getElementById("addCopyId");
          // if (getHeadingId && !addCopyId) {
          if (getHeadingId) {
            const spanElem = document.createElement("span");
            const copyElem = document.createElement("span");
            // spanElem.id = "addCopyId";
            spanElem.style.cssText = `background: #aad; margin-left: .2rem; font-size: 16px;`;
            spanElem.innerHTML = `${getHeadingId}`;
            copyElem.innerHTML = "Copy";
            copyElem.onclick = (e) => handleCopy(e, `${getHeadingId}`);
            copyElem.style.cssText = `background: teal; color: white; border-radius: 999px; padding: 2px 4px; font-size: 14px; margin-left: .2rem; cursor: pointer;`;
            heading.appendChild(spanElem);
            heading.appendChild(copyElem);
          }
        });
    }
  }, [bodyData]);

  // PAGE SCROLL HIGHLIGHT HEADING start-testing-code-success
  // useEffect(() => {
  //   if (containerRef.current) {
  //     const elements = containerRef.current.querySelectorAll(
  //       "h1, h2, h3, h4"
  //     );
  //     // Store allTags for creating navigation links
  //     // setElementsContent(Array.from(elements));

  //     // Observer to track when elements come into view
  //     const observer = new IntersectionObserver(
  //       (entries) => {
  //         entries.forEach((entry) => {
  //           if (entry.isIntersecting) {
  //             highlightElement(entry.target);
  //           }
  //         });
  //       },
  //       { rootMargin: "0px 0px -60% 0px", threshold: 0.1 } // Adjust rootMargin to trigger midway
  //     );

  //     // Add click event listener to each element
  //     elements.forEach((element, index) => {
  //       element.setAttribute("id", `tag-${index}`); // Assign unique ID to each tag
  //       observer.observe(element); // Observe each tag

  //       element.addEventListener("click", (e) => {
  //         e.preventDefault();

  //         // Scroll the clicked element into view
  //         element.scrollIntoView({
  //           behavior: "smooth",
  //           block: "center",
  //           // block: "start",
  //           inline: "start",
  //         });
  //         // Highlight the clicked element
  //         highlightElement(element);
  //       });
  //     });

  //     // Cleanup event listeners on component unmount
  //     return () => {
  //       elements.forEach((element) => observer.unobserve(element));
  //     };
  //   }
  // }, [bodyData, activeElement]);

  // MAIN FUNCTION FOR TRACKING
  const highlightElement = (element) => {
    setActiveElement(element.getAttribute("kpk"));
  };

  // PAGE SCROLL HIGHLIGHT HEADING start
  const [isParentHeading, setIsParentHeading] = useState(null);
  const highlightParentElement = async (identity) => {
    if (sidebarRef.current) {
      // const activeElement = document.getElementById(id);
      const activeElement = document.querySelector(`[identity="${identity}"]`);
      // const activeElement= sidebarRef?.current.querySelector(`#${id}`);

      if (activeElement) {
        // activeElement.style.backgroundColor = "lightblue"; // Highlight the element
        // console.log("Unique element:", activeElement);
      }

      // console.log("init-parent_H===>", activeElement);

      if (activeElement) {
        const level = parseInt(activeElement.getAttribute("dropdownlevel"));
        const parentLevel = level - 1;
        if (parentLevel >= 0) {
          const parentLevelClass = ".dropdownLevel-" + parentLevel;
          const parentElement = activeElement
            .closest(parentLevelClass)
            .querySelector(parentLevelClass);
          // console.log(parentElement, parentLevelClass, parentLevel);
          // parentElement.classList.add("parentHeadingClass");
          // parentElement.removeAttribute("style");
          // parentElement.style.cssText="background: red !important; font-weight: 700; color: green !important;";

          const identityAttr = parentElement.getAttribute("identity");

          // setIsParentHeading(parentElement.identity);
          // console.log("iiiiiddd===>", parentElement.identity)
          // console.log("iiiiiddd===>", identityAttr);
          // parentElement.classList.add("parentHeadingClass");
          // parentElement.style.cssText="border: 6px solid red !important; background-color: orange !important;";

          const gettingIdenityAttrFromLocalS = localStorage.getItem(
            "parentHeadingIdentity"
          );
          // if (identityAttr !== gettingIdenityAttrFromLocalS) {
          setIsParentHeading(identityAttr);
          // parentElement.style.backgroundColor= "yellow !important";

          // const getPreviousPHeadingIcon = document.querySelector(
          //   `[iconidentity="${gettingIdenityAttrFromLocalS}"]`
          // );
          // if (getPreviousPHeadingIcon) {
          //   // await getPreviousPHeadingIcon.click();
          // }

          // localStorage.setItem("parentHeadingIdentity", identityAttr);
          // }
        }
      }
    }
  };
  // console.log("iiiiiddd-2===>", isParentHeading);

  // PAGE SCROLL HIGHLIGHT HEADING end

  // GEATHER A TAG FROM POST
  useEffect(() => {
    // Date: 31 oct 2024, changes as per Manigandan.
    containerRef.current.querySelectorAll("a").forEach((link) => {
      const remove1stImg = link.querySelector("img");
      if (remove1stImg) {
        link.removeChild(remove1stImg);
      }
      const getHrefAtrr = link.getAttribute("href");
      // const ext = link.getAttribute("href").split(".").pop();
      const ext = getHrefAtrr?.split(".").pop();
      // let path = "./icons/";
      // let icon = "";

      // for page scrolling
      // const getHrefAtrr = link.getAttribute("href");
      const idLink = getHrefAtrr?.startsWith("#");
      if (idLink === true) {
        link.removeAttribute("target");
      }
      if (idLink === false) {
        // link.removeAttribute("target"); // date: 27 nov 2024
        // Date: 15 nov 2024, changes as per Manigandan.
        link.setAttribute("target", "_blank");
        link.removeAttribute("download");
      }

      // itself Link: same tab open
      // const domainToFind = 'askcn.cloudframe.com';
      // const isDomainIncluded = getHrefAtrr?.includes(domainToFind);
      // const domainRegex = /https?:\/\/(www\.)?askcndev\.cloudframe\.com\b/;
      const domainRegex = /https?:\/\/(www\.)?ask\.cloudframe\.com\b/;
      const isDomainIncluded = domainRegex.test(getHrefAtrr);
      if (isDomainIncluded) {
        link.removeAttribute("target");
        // Date: 28 Feb 2025
        if (!isOpenLink) {
          link.onclick = (event) => stopCheckLinkClickFunc(event, getHrefAtrr);
        }
      } else if (isDomainIncluded === false && idLink === false) {
        // link.removeAttribute("target"); // date: 27 nov 2024
        // Date: 15 nov 2024, changes as per Manigandan.
        link.setAttribute("target", "_blank");
        link.removeAttribute("download");
      }

      link.style.cssText = `text-decoration: none; color: #00a3d0; cursor: pointer;`;
      // link.style.color = darkTheme ? "#11d9f0" : "#00B3F0";
      // link.style.color = "#00a3d0";

      // if (ext === "pdf" || "zip" || "xlsx" || "csv" || "java") {
      //   link.removeAttribute("target");
      //   link.setAttribute("download", "");
      //   // link.style.cssText = `color: #00B3F0; text-decoration: none;`;
      // }else{
      //   link.setAttribute("target", "_blank");
      // }
      // {
      // link.setAttribute("target", "_blank");
      // link.addEventListener('mouseover', () => {
      //   link.style.color = '#3182ce';
      //   link.style.textDecoration = 'underline';
      // });
      // link.addEventListener('mouseout', () => {
      //   link.style.color = '#00B3F0';
      //   link.style.textDecoration = 'none';
      // });
      // }
      // console.log("exttt", ext)
      // switch (ext) {
      //   case "pdf":
      //     icon = "pdf-icon.png"; // Add path to your icon images
      //     break;
      //   case "zip":
      //     icon = "zip-icon.png";
      //     break;
      //   case "xlsx":
      //     icon = "xlsx-icon.png";
      //     break;
      //   case "csv":
      //     icon = "csv-icon.png";
      //     break;
      //   case "txt":
      //     icon = "txt-icon.png";
      //     break;
      //   case "java":
      //     icon = "java-icon.png";
      //     break;
      //   default:
      //     icon = "default-icon.png";
      //     break;
      // }
      // let img = document.createElement("img");
      // img.src = path + icon;
      // img.classList.add("icon");
      // link.prepend(img);
      // link.setAttribute("download", "");
      if (ext === "pdf") {
        // icon = "pdf-icon.png";
        // link.removeAttribute("target");
        // link.setAttribute("download", "");
        let img1 = document.createElement("img");
        // img1.src = path + icon;
        img1.src = pdfImg;
        img1.classList.add("icon");
        link.prepend(img1);
      }
      // else {
      // const href=link.getAttribute("href");
      // const idLink= link.getAttribute("href")?.startsWith("#")
      // // const hostname = new URL(href).hostname;
      // // console.log("hrff",idLink)
      // if(!idLink){
      //   link.setAttribute("target", "_blank");
      //   link.removeAttribute("download");
      // }
      //   link.setAttribute("target", "_blank");
      //   link.removeAttribute("download");
      // }
      if (ext === "zip") {
        // icon = "zip-icon.png";
        link.removeAttribute("target");
        // link.setAttribute("download", "test-tet.zip");
        let img2 = document.createElement("img");
        // img2.src = path + icon;
        img2.src = zipImg;
        img2.classList.add("icon");
        link.prepend(img2);
      }
      if (ext === "xlsx") {
        // icon = "xlsx-icon.png";
        link.removeAttribute("target");
        // link.setAttribute("download", "");
        let img3 = document.createElement("img");
        // img3.src = path + icon;
        img3.src = xlsxImg;
        img3.classList.add("icon");
        link.prepend(img3);
      }
      if (ext === "csv") {
        // icon = "csv-icon.png";
        link.removeAttribute("target");
        // link.setAttribute("download", "");
        let img4 = document.createElement("img");
        // img4.src = path + icon;
        img4.src = csvImg;
        img4.classList.add("icon");
        link.prepend(img4);
      }
      if (ext === "java") {
        // icon = "java-icon.png";
        link.removeAttribute("target");
        // link.setAttribute("download", "");
        let img5 = document.createElement("img");
        // img5.src = path + icon;
        img5.src = javaImg;
        img5.classList.add("icon");
        link.prepend(img5);
      }
      if (ext === "txt") {
        // icon = "txt-icon.png";
        link.removeAttribute("target");
        // link.setAttribute("download", "");
        let img6 = document.createElement("img");
        // img6.src = path + icon;
        img6.src = txtImg;
        img6.classList.add("icon");
        link.prepend(img6);
      }
    });
  }, [bodyData]);

  // Set up IntersectionObserver to update activeHeadingId
  //  useEffect(() => {
  //   if (!containerRef.current) return;

  //   const headings = Array.from(containerRef.current.querySelectorAll('h1, h2, h3, h4'));
  //   console.log('Observed Headings:', headings.map(h => h.id)); // Check for IDs

  //   const observerOptions = {
  //     root: containerRef.current, // The scrollable container
  //     rootMargin: '0px 0px -70% 0px', // Adjust to mark items as active earlier or later
  //     threshold: 0.1 // When 10% of an element is visible, consider it intersecting
  //   };

  //   const observerCallback = (entries) => {
  //     // Find the first intersecting entry
  //     const visibleEntry = entries.find(entry => entry.isIntersecting);

  //     // Update active heading ID if a visible heading is found
  //     if (visibleEntry) {
  //       console.log('Visible heading:', visibleEntry.target.id); // Debugging line
  //       setActiveHeadingId(visibleEntry.target.id);
  //     }
  //   };

  //   // Create a new IntersectionObserver with the above options
  //   const observer = new IntersectionObserver(observerCallback, observerOptions);

  //   // Observe each heading
  //   headings.forEach((heading) => {
  //     if (heading.id) {
  //       observer.observe(heading);
  //     }
  //   });

  //   // Cleanup observer when the component is unmounted or rawHTML changes
  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [bodyData]);

  // Scroll to a specific heading when clicking a sidebar item
  // const scrollToHeading = (id) => {
  //   const heading = containerRef.current.querySelector(`#${id}`);
  //   if (heading) {
  //     heading.scrollIntoView({ behavior: "smooth", block: "start" });
  //     setActiveHeadingId(id);
  //   }
  // };

  // // Function to scroll to a heading when clicked in the sidebar
  const scrollToHeading = (kpk) => {
    // const target = document.getElementById(id);
    const safeKPK = CSS.escape(kpk);
    // const target = document.querySelector(`[title="${title}"]`); // workin fine
    const target = document.querySelector(`[kpk="${safeKPK}"]`);
    if (target) {
      target.scrollIntoView({ behavior: "smooth", block: "start" });
      setActiveHeadingId(kpk);
    }
  };

  // Toggle the expanded state of a heading
  const toggleHeading = (kpk) => {
    setExpandedHeadings((prevState) => ({
      ...prevState,
      [kpk]: !prevState[kpk],
    }));
  };

  // RIGHT PAGE SET-UP ========KPK-END

  // if (allPosts.length <= 0) {
  //   const goingLoginFunc = () => {
  //     setResToken("");
  //     setCodeNavigatorToken("");
  //     localStorage.removeItem("access_token")
  //     localStorage.removeItem("cn_access_token")
  //     navigate("/");
  //   };
  //   return (
  //     <div className="flex items-center justify-center flex-col h-screen px-3 gap-y-4">
  //       {/* <div className={`text-lg`}>404</div> */}
  //       <div className="{`text-lg`}">Session has been expired.</div>
  //       <button
  //         type="submit"
  //         className={`px-11 py-2 rounded-full bg-lighGray text-Black flex items-center gap-1 flex-wrap transition-all font-medium font-Barlow_medium hover:bg-White active:bg-opacity-60`}
  //         onClick={(e) => goingLoginFunc(e)}
  //       >
  //         Go to Login Page
  //       </button>
  //     </div>
  //   );
  // }

  //SETUP 2-hours SESSION EXPIRATION
  const cnAccessT = localStorage.getItem("cn_access_token");
  // useEffect(() => {
  //   if (tokenMode === "codenavigator") {
  //     const timer = setTimeout(() => {
  //       localStorage.removeItem("access_token");
  //       localStorage.removeItem("cn_access_token");
  //       setResToken("");
  //       setCodeNavigatorToken("");
  //       navigate("/");
  //       // }, 7200000);  // for-2hours
  //     }, 6960000); // for 1hour and 56 min.

  //     // console.log("cn-expiration-time-added");
  //     return () => clearTimeout(timer);
  //   }
  //   // }, [tokenMode === "codenavigator"]);
  // }, [cnAccessT]);

  // COOKIE EXPIRATION AND REFRESH TOKEN
  // const getCNaccessTokenFromRefreshToken = async () => {
  //   const rToken = localStorage.getItem("cn_rt");
  //   const res = await fetch(`${BaseURL}/get-accesstoken-from-refreshtoken`, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     // body: JSON.stringify({ refresh_token: rToken }),
  //     body: "",
  //     credentials: 'include'
  //   });

  //   const data = await res.json();

  //   if (data?.access_token) {
  //     localStorage.removeItem("access_token");
  //     localStorage.removeItem("cn_access_token");
  //     localStorage.removeItem("cn_rt");

  //     localStorage.setItem("cn_access_token", data.access_token);
  //     localStorage.setItem("cn_rt", data.refresh_token);
  //     setCodeNavigatorToken(data.access_token);
  //     // setCodeNavigatorToken("");
  //     // setResToken("");
  //   }
  // };
  // COOKIE REFRESH TOKEN
  // useEffect(() => {
  //   if (tokenMode === "codenavigator") {
  //     const timer = setInterval(() => {
  //       getCNaccessTokenFromRefreshToken();
  //       // }, 7200000);  // for-2hours
  //     // }, 6960000); // for 1hour and 56 min.
  //     }, 3000); // testing.

  //     // console.log("cn-expiration-time-added");
  //     return () => clearInterval(timer);
  //   }
  //   // }, [tokenMode === "codenavigator"]);
  // }, [tokenMode]);
  // // }, [cnAccessT]);

  // LEFT SIDE-BAR SCROLL WITH POST
  useEffect(() => {
    if (bodyData) {
      sidebarRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isSearchOpen]);
  // }, [bodyData]);

  // LEFT SIDE-BAR SCROLL WITH POST
  const linkMounted = useRef(false);
  useEffect(() => {
    const bodyTime = setTimeout(() => {
      setInitialLeftScrollTrue(true);
    }, 1200);
    return () => clearTimeout(bodyTime);
  }, [leftScrollFlag]);
  // 1ST SCROLL TEST
  useEffect(() => {
    // if (!linkMounted.current) {
    //   linkMounted.current = true;
    //   return;
    // }

    // console.log("observer-outer");
    if (sidebarRef?.current && bodyData) {
      sidebarRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setInitialLeftScrollTrue(false);
      // console.log("observer-inside");
    }
  }, [initialLeftScrollTrue]);
  // console.log("observer", initialLeftScrollTrue, sidebarRef?.current, headings);
  // useEffect(() => {
  //   // if (!linkMounted.current) {
  //   //   linkMounted.current = true;
  //   //   return;
  //   // }

  //   // console.log("observer-outer");

  //   const lefbarScroll = setTimeout(() => {
  //     if (bodyData && sidebarRef?.current) {
  //       sidebarRef.current.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //       // console.log("observer-inside");
  //     }
  //     setInitialLeftScrollTrue(false);
  //   }, 1300);

  //   return () => clearTimeout(lefbarScroll); // Cleanup timeout on unmount
  // }, [initialLeftScrollTrue]);
  // console.log("observer", initialLeftScrollTrue);

  // 2ND SCROLL TEST
  // useLayoutEffect(() => {
  //   if (!linkMounted.current) {
  //     linkMounted.current = true;
  //     return;
  //   }

  //   // const lefbarScroll = setTimeout(() => {
  //     if (bodyData && sidebarRef?.current) {
  //       sidebarRef.current.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //       setInitialLeftScrollTrue(false);
  //     }
  //   // }, 8000);

  //   // return () => clearTimeout(lefbarScroll);  // Cleanup timeout on unmount
  // }, [bodyData && initialLeftScrollTrue]);
  // 3rd SCROLL TEST
  // useEffect(() => {
  //   if (!linkMounted.current) {
  //     linkMounted.current = true;
  //     return;
  //   }

  //   console.log("observer-outer")

  //   const observer = new MutationObserver(() => {
  //     if (sidebarRef.current) {
  //       sidebarRef.current.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //       console.log("observer-inside")
  //       observer.disconnect();
  //     }
  //   });

  //   observer.observe(document.body, { childList: true, subtree: true });

  //   return () => observer.disconnect();
  // }, [bodyData && initialLeftScrollTrue]);
  // console.log("observer", initialLeftScrollTrue)

  // 0TH SCROLL TEST
  // useEffect(() => {
  //   if (!sidebarRef.current) return;

  //   const observer = new MutationObserver((mutationsList) => {
  //     for (let mutation of mutationsList) {
  //       // Check if a `ul` element has been added to the sidebarRef
  //       if (mutation.type === 'childList' && sidebarRef.current.querySelector('ul')) {
  //         sidebarRef.current.scrollIntoView({
  //           behavior: 'smooth',
  //           block: 'center',
  //         });
  //         observer.disconnect(); // Stop observing after the scroll
  //         break;
  //       }
  //     }
  //   });

  //   // Observe for childList changes (e.g., when `ul` is added)
  //   observer.observe(sidebarRef.current, { childList: true, subtree: true });

  //   // Clean up observer on component unmount
  //   return () => observer.disconnect();
  // }, []);
  // console.log("observer", initialLeftScrollTrue, sidebarRef?.current, headings)

  const getUniqueId = () =>
    Date.now().toString(36) + Math.random().toString(36).substring(2, 10);

  // Recursive sidebar rendering based on heading hierarchy
  const SidebarMenu = ({
    headings,
    onHeadingClick,
    onToggleClick,
    activeHeadingId,
    expandedHeadings,
    paddLeft,
    margLeft,
  }) => {
    return (
      <ul
        style={{ paddingLeft: `${paddLeft}`, marginLeft: `${margLeft}` }}
        className={`list-none border-l ${
          darkTheme ? "border-l-BoldGray" : "border-l-[#BCD]"
        }`}
        ref={sidebarRef}
      >
        {/* Date: 7 Jan, 2025
      // Ref: Sent a whatsapp message by Manigandan for some bux of heading when hover then we saw some unique number.
      // Changes: "heading.title" replaced with "heading.kpk" only. */}
        {headings.map((heading, index) => (
          <li key={index} className={heading.dropdownLevelClass}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                identity={`${heading.kpk}`}
                dropdownlevel={heading.dropdownlevel}
                onClick={() => {
                  onHeadingClick(heading.kpk);
                  onToggleClick(heading.kpk);
                  // setActiveHeadingId(heading.id);

                  setIsParentHeadingClicked(`${heading.kpk}`);
                  // setIsParentHeadingClicked(true);
                  // setIsParentHeading(`${heading.title}`);
                  setIsParentHeading(null);

                  // date: 27 nov 2024 as per Manigandan
                  // Scroll to the tag when clicked in the sidebar
                  // const target = document.getElementById(`tag-${heading.id}`);
                  // const target = document.querySelector(`[title="tag-${heading.title}"]`); // working-fine
                }}
                style={{
                  display: "block",
                  width: "100%",
                  padding: "4px 10px",
                  // backgroundColor:
                  //   activeHeadingId === heading.id
                  //     ? "#faafdd"
                  //     : "transparent",
                  border: "none",
                  textAlign: "left",
                  cursor: "pointer",
                  fontStyle: heading.tagName === "H1" ? "normal" : "normal",

                  // backgroundColor:
                  //   isParentHeading === `${heading.title}` &&
                  //   "yellow",
                  // ${
                  //   isParentHeading === `${heading.title}` &&
                  //   "!font-bold !bg-Green"
                  // }
                }}
                // className={`clamped-text transition-all ${
                //   activeElement === `tag-${heading.title}`
                //     ? `${darkTheme ? "bg-[#1c3c68]" : "bg-[#dbe5ee]"}`
                //     : ""
                //   // activeElement === `tag-${index}` ? "active" : ""
                // } ${darkTheme ? "hover:bg-[#1c3c68]" : "hover:bg-[#dbe5ee]"} ${
                //   activeHeadingId === heading.title
                //     ? darkTheme
                //       ? "bg-[#1c3c68]"
                //       : "bg-[#dbe5ee]"
                //     : ""
                // }`}
                // Date: 11 Dec, 2024
                // Ref: I improved scroll effect for auto highlight when scrolling and clicked heading scroll, for original code please go to filename: Home copy 6.
                // Changes: Added class codes and comment previous class codes.
                className={`clamped-text transition-all ${
                  heading.dropdownLevelClass
                } ${
                  isParentHeading === `${heading.kpk}` &&
                  `${darkTheme ? "bg-[#1c3c68]" : "bg-[#dbe5ee]"}`
                } ${
                  activeHeadingId === heading.kpk
                    ? `${darkTheme ? "bg-[#1c3c68]" : "bg-[#dbe5ee]"}`
                    : ``
                }`}
              >
                <div className={`line-clamp-2`}>{heading.text}</div>
              </button>
              {/* ARROW ICONS  */}
              {heading.children && (
                <button
                  iconidentity={heading.kpk}
                  onClick={() => onToggleClick(heading.kpk)}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    // padding: "5px",
                  }}
                  className={`pr-3`}
                >
                  {/* {expandedHeadings[heading.id] ? "-" : "+"} */}
                  {!expandedHeadings[heading.kpk]
                    ? heading.children.length > 0 && (
                        <MdKeyboardArrowRight
                          className={`w-5 h-5 transition-all ${
                            darkTheme ? "text-BoldGray" : "text-VLightGray"
                          }`}
                        />
                      )
                    : heading.children.length > 0 && (
                        <MdKeyboardArrowRight
                          className={`w-5 h-5 transition-all rotate-90 ${
                            darkTheme ? "text-BoldGray" : "text-VLightGray"
                          }`}
                        />
                      )}
                </button>
              )}
            </div>
            {heading.children && expandedHeadings[heading.kpk] && (
              <SidebarMenu
                headings={heading.children}
                onHeadingClick={onHeadingClick}
                onToggleClick={onToggleClick}
                activeHeadingId={activeHeadingId}
                expandedHeadings={expandedHeadings}
                paddLeft={"11px"}
                margLeft={"11px"}
              />
            )}
          </li>
        ))}
      </ul>
    );
  };

  // 7 Feb 2025
  // right side bar collapse functionality
  // const [removeGrid, setRemoveGrid] = useState("grid");
  // const [leftBarHide, setleftBarHide] = useState("block");
  // const [iconContentTitle, setIconContentTitle] = useState(
  //   "Close table of contents"
  // );

  // const collapseLeftBarFun = () => {
  //   setleftBarHide(leftBarHide === "block" ? "hidden" : "block");
  //   setRemoveGrid(removeGrid === "grid" ? "" : "grid");
  //   setIconContentTitle(
  //     iconContentTitle === "Close table of contents"
  //       ? "Open table of contents"
  //       : "Close table of contents"
  //   );
  // };

  // FEEDBACK MODAL HANDLER
  const [isFeedbackModal, setIsFeedbackModal] = useState(false);
  const [isFeedbackModalPosi, setIsFeedbackModalPosi] = useState(false);
  const feedbackModalToggle = () => {
    setIsFeedbackModal(!isFeedbackModal);
  };
  const feedbackModalTogglePosi = () => {
    setIsFeedbackModalPosi(!isFeedbackModalPosi);
  };

  // PREV BTN IMPLEMENTATION

  const [isOnCurrentUrl, setIsOnCurrentUrl] = useState(true); // Tracks which URL we are currently on
  const location = useLocation();

  // SCROLL LEFTBAR WHEN PREV BTN CLICKED
  useEffect(() => {
    if (bodyData) {
      sidebarRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setIsOnCurrentUrl(true);
    }
  }, [!isOnCurrentUrl]);
  // }, [bodyData]);

  // Load URLs from localStorage on initial render or location change
  useEffect(() => {
    const currentUrl = location.pathname;

    // Get the previous URL from localStorage
    const storedPreviousUrl = localStorage.getItem("previousUrl");
    const storedCurrentUrl = localStorage.getItem("currentUrl");

    // Store the new previous URL in localStorage if there is a current one
    if (storedCurrentUrl && storedCurrentUrl !== currentUrl) {
      localStorage.setItem("previousUrl", storedCurrentUrl);
    }

    // Update the current URL in localStorage
    localStorage.setItem("currentUrl", currentUrl);
  }, [location]);

  const handlePrevBackBtn = () => {
    const currentUrl = localStorage.getItem("currentUrl");
    const previousUrl = localStorage.getItem("previousUrl");

    if (previousUrl) {
      // Toggle navigation between previous and current URLs
      // if (isOnCurrentUrl) {
      //   navigate(previousUrl);
      // } else {
      //   navigate(currentUrl);
      // }

      const mainUrlTogglePrev = isOnCurrentUrl ? previousUrl : currentUrl;
      // console.log("location=middle=", previousUrl);
      // console.log("isOnCurrentUrl=middle=", isOnCurrentUrl);
      if (mainUrlTogglePrev !== location.pathname) {
        // if (previousUrl !== location.pathname) {
        // if (mainUrlTogglePrev) {

        // const freshSlug = previousUrl.replace(/\//g, "");
        // const freshSlug = mainUrlTogglePrev.replace(/\//g, ""); // without versioning code
        // 28 Jan 2025
        // const freshSlug = mainUrlTogglePrev.replace(/\/\d+\.\d+\.\d+\//, ""); //working fine

        // const freshSlug = mainUrlTogglePrev.replace(/\/\d+\.\d+\.\d+\//, ""); //final

        // Date: 24 Feb 2025
        // const freshSlug = mainUrlTogglePrev.replace(/^\/[\d.]+\//, ""); //final with all Versions detects.

        // Date: 27 Feb 2025
        const withVersionFreshSlug = mainUrlTogglePrev.replace(
          /^\/[\d.]+\//,
          ""
        ); //final with all Versions detects.
        const withoutFreshSlug = mainUrlTogglePrev.replace(/\//g, ""); // without versioning code
        const freshSlug = versionName ? withVersionFreshSlug : withoutFreshSlug;

        console.log("freshSlug==>", freshSlug);
        const updatedData = allPosts.map((item) => {
          return {
            ...item,
            posts: item.posts.map((post) => ({
              ...post,
              name: item.name,
            })),
          };
        });
        const SingleArray = updatedData.flatMap((post) => post.posts);

        const filteredData =
          SingleArray.filter((item) => item.slug === freshSlug) || [];

        if (filteredData.length > 0) {
          subToggleDropdown(
            filteredData[0].title,
            filteredData[0],
            0,
            filteredData[0].name
          );
        }

        // navigate(previousUrl, { replace: true });
        navigate(mainUrlTogglePrev, { replace: true });
        setIsOnCurrentUrl(false);
      }

      // Flip the toggle to switch URLs on the next click
      // setIsOnCurrentUrl(!isOnCurrentUrl);

      // WORKING
      // setIsOnCurrentUrl(true);
    } else {
      // console.log("No previous URL to toggle to.");
    }
  };

  // IMPL. COPY BUTTON FOR PRE TAGS
  useEffect(() => {
    const container = containerRef.current;
    const handleCopy = (e, content) => {
      const copyElem = e.target;
      const codeContent = content.innerText.replace("Copy", "");
      navigator.clipboard.writeText(codeContent).then(
        () => {
          // toast.success("Copied to clipboard!");
          // 29 Jan 2025
          copyElem.innerHTML = "Copied!";
          setTimeout(() => {
            copyElem.innerHTML = "Copy";
          }, 1500);
        },
        (err) => {
          console.error("Failed to copy text: ", err);
        }
      );
    };
    if (container) {
      const preTags = container.querySelectorAll("pre");

      preTags.forEach((preTag, index) => {
        const btn = preTag.querySelector("button");
        if (btn) btn.remove();
        preTag.style.cssText = `position: relative;`;
        const copyButton = document.createElement("button");
        copyButton.innerHTML = `Copy`;
        // copyButton.style.marginLeft = ".5rem";
        copyButton.style.cssText =
          "position:absolute; right: .3rem; top: .3rem; background-color: #236; transition: 0.4s; padding: .2rem .8rem; border-radius: 1rem;";

        // Simulate :hover using mouseenter and mouseleave
        copyButton.addEventListener("mouseenter", () => {
          copyButton.style.backgroundColor = "#0a61dc";
        });
        copyButton.addEventListener("mouseleave", () => {
          copyButton.style.backgroundColor = "#236";
        });

        // Simulate :active using mousedown and mouseup
        copyButton.addEventListener("mousedown", () => {
          copyButton.style.backgroundColor = "transparent";
        });
        copyButton.addEventListener("mouseup", () => {
          copyButton.style.backgroundColor = "#0a61dc";
        });
        // copyButton.onclick = () => handleCopy(preTag.textContent);
        // copyButton.onclick = () => handleCopy(preTag.innerText);
        copyButton.onclick = (e) => handleCopy(e, preTag);
        preTag.appendChild(copyButton);
      });
    }
  }, [bodyData]);

  // Date: 29 Jan 2025
  // const hasMountedVideoContainerRef = useRef(false);
  useEffect(() => {
    // if (!hasMountedVideoContainerRef.current) {
    //   hasMountedVideoContainerRef.current = true;
    //   return;
    // }
    const contentEl = containerRef.current;
    const videoUrlFunc = (videoId) => {
      // const videoUrl = `https://askcfbackenddev.cloudframe.com/api/personal/play-video?vid=${videoId}`;
      const videoUrl = `https://askcfbackend.cloudframe.com/api/personal/play-video?vid=${videoId}`;
      return videoUrl;
    };

    if (contentEl) {
      const allVideoContainer = Array.from(
        contentEl.querySelectorAll(".videoContainer")
      );
      allVideoContainer.forEach((item, index) => {
        item.innerHTML = "";
        const getVideoId = item.getAttribute("data-vid");
        const createIFrameElem = document.createElement("iframe");
        createIFrameElem.src = videoUrlFunc(getVideoId);
        // createIFrameElem.width = "100%";

        // createIFrameElem.height = "350px";
        // Date: 19 Feb 2025
        // createIFrameElem.height = "600px";
        // createIFrameElem.scrolling = "no";

        createIFrameElem.allow = "autoplay; fullscreen";
        createIFrameElem.title = "Video";

        // createIFrameElem.className = `m-4 rounded-xl w-[92%] md:w-[500px]`;
        // Date: 19 Feb 2025
        createIFrameElem.className = `m-4 rounded-xl w-[92%] md:w-[400px] md:w-[80%] lg:w-[84%] xl:w-[80%] h-[360px] md:h-[300px] lg:h-[380px] xl:h-[430px] 2xl:h-[560px]`;

        item.append(createIFrameElem);
      });
    }
  }, [bodyData]);
  return (
    <div>
      {/* TOP MENU FOR small-devices  */}
      {isSmMenuOpen && (
        <div
          className={`HeightHome block md:hidden absolute z-[50] top-14 left-0 right-0 ${
            darkTheme ? "bg-LightBlack" : "bg-White"
          }`}
        >
          <TopMenuHeader
            openMenu={openMenu}
            toggleDropdown={toggleDropdown}
            subToggleDropdown={subToggleDropdown}
            headings={headings}
            // sidebar for displaying dropdown
            SidebarMenu={
              <SidebarMenu
                headings={headings}
                onHeadingClick={scrollToHeading}
                onToggleClick={toggleHeading}
                activeHeadingId={activeHeadingId}
                expandedHeadings={expandedHeadings}
                paddLeft={"12px"}
                margLeft={"12px"}
              />
            }
            // bar hide show functionality
            leftBarHide={leftBarHide}
            collapseLeftBarFun={collapseLeftBarFun}
            iconContentTitle={iconContentTitle}
          />
        </div>
      )}
      {isSearchOpen && (
        <div
          className="absolute z-40 top-0 left-0 right-0 bottom-0 bg-[#c8c8da6b] backdrop-blur h-screen"
          onClick={() => SearchToggle()}
        >
          <Search
            searchInp={searchInp}
            setSearchInp={setSearchInp}
            subToggleDropdown={subToggleDropdown}
          />
        </div>
      )}

      {isFeedbackModal && (
        <div
          className="absolute z-40 top-0 left-0 right-0 bottom-0 bg-[#c8c8da6b] backdrop-blur h-screen flex items-center justify-center"
          onClick={() => feedbackModalToggle()}
        >
          {/* <div className={`bg-Green`} onClick={(e) => e.stopPropagation()}>
            <div onClick={() => feedbackModalToggle()}>click</div>
          </div> */}
          <FeedbackModal
            // subTitle={"What can we do to improve the content?"}
            subTitle={"Great! Let us know what you found helpful."}
            feedbackModalToggle={feedbackModalToggle}
            // feedback={"0"}
          />
        </div>
      )}
      {/* Date: 28 Feb 2025  */}
      {isArticleNotFound && (
        <div
          className="absolute z-40 top-0 left-0 right-0 bottom-0 bg-[#c8c8da6b] backdrop-blur h-screen flex items-center justify-center"
          onClick={() => setIsArticleNotFound(false)}
        >
          <ArticleNotFoundModal setIsArticleNotFound={setIsArticleNotFound} />
        </div>
      )}
      {/* Date: 1 March 2025  */}
      {isVersionArticleNotFound && (
        <div
          className="absolute z-40 top-0 left-0 right-0 bottom-0 bg-[#c8c8da6b] backdrop-blur h-screen flex items-center justify-center"
          onClick={() => {
            setIsVersionArticleNotFound(false);
            setStoreClickedVersion(""); // Date: 1 March 2025
          }}
        >
          <ArticleNotFoundByVersionModal />
        </div>
      )}
      {/* {isFeedbackModalPosi && (
        <div
          className="absolute z-40 top-0 left-0 right-0 bottom-0 bg-[#c8c8da6b] backdrop-blur h-screen flex items-center justify-center"
          onClick={() => feedbackModalTogglePosi()}
        >
          <FeedbackModal
            subTitle="Great! Let us know what you found helpful."
            feedbackModalToggle={feedbackModalTogglePosi}
            feedback={"1"}
          />
        </div>
      )} */}

      {/* BREAD CRUMB ======== */}
      {/* <div
      className={`grid grid-cols-1 md:grid-cols-3 shadow-md h-[30px] ${
          darkTheme ? "bg-LightBlack" : "bg-lighGray"
        }`}
      >
        <div className="flex items-center">
          <div
            onClick={() => collapseLeftBarFun()}
            className={`hidden md:block p-0.5 px-4 mx-2 active:bg-gray ${
              darkTheme ? "bg-[#252929]" : "bg-White"
            } rounded-lg`}
            title={iconContentTitle}
          >
            {leftBarHide === "block" ? (
              <IoIosArrowBack className={`w-5 h-5`} />
            ) : (
              <IoIosArrowForward className={`w-5 h-5`} />
            )}
          </div>
        </div>
        <div className="md:col-span-2 flex items-center">
          <div className="line-clamp-1 px-2 md:px-0">
            {breadCrumbCategory} <span>/</span> {breadCrumb}
          </div>
        </div>
      </div> */}

      {/* MAIN PAGE grid WRAPPER =====
      =========================== */}
      <div className={`md:px-0 grid grid-cols-1 md:grid-cols-[auto,1fr,1fr]`}>
        <div
          // onClick={() => collapseLeftBarFun()}
          // title={iconContentTitle}
          className={`scrollBar-container cursor-pointer w-0 hidden ${
            leftBarHide === "block" ? "md:hidden" : "md:block"
          } ${darkTheme ? "bg-[#262626]" : "bg-[#eff3f8]"}`}
        >
          {/* 7 Feb 2025 */}
          {/* {leftBarHide !== "block" && (
            <Tooltip
              title="Open table of contents"
              position="right"
              trigger="mouseenter"
              // theme={darkTheme ? "dark" : "light"}
              size="regular"
              delay={1000}
            >
              <div
                className={`flex items-center justify-center m-1 py-1.5 transition-all rounded-xl ${
                  darkTheme
                    ? "hover:bg-bgCF active:bg-[#3786f5] hover:text-White"
                    : "hover:bg-bgCF active:bg-[#3786f5] hover:text-White"
                }`}
                // className={`flex items-center justify-center py-2 transition-all rounded-xl ${
                //   darkTheme
                //     ? "hover:bg-[#0dd3ff] hover:text-Black active:bg-[#67e8f9a2]"
                //     : "hover:bg-[#ffe3b9] active:bg-[#ffddaa9d]"
                // }`}
              >
                <IoIosArrowForward className={`w-5 h-5`} />
              </div>
            </Tooltip>
          )} */}
        </div>
        <div
          className={`scrollBar-container md:w-[300px] lg:w-[400px] xl:w-[420px] ${leftBarHide}`}
        >
          <LeftHome
            // openMenu={openMenu}
            // selectedHighlight={selectedHighlight}
            toggleDropdown={toggleDropdown}
            subToggleDropdown={subToggleDropdown}
            // page heading
            // elementsContent={elementsContent}
            // highlightElement={highlightElement}
            // activeElement={activeElement}
            headings={headings}
            // sidebar for displaying dropdown
            SidebarMenu={
              <SidebarMenu
                headings={headings}
                onHeadingClick={scrollToHeading}
                onToggleClick={toggleHeading}
                activeHeadingId={activeHeadingId}
                expandedHeadings={expandedHeadings}
                paddLeft={"12px"}
                margLeft={"12px"}
              />
            }
            // bar hide show functionality
            leftBarHide={leftBarHide}
            collapseLeftBarFun={collapseLeftBarFun}
            iconContentTitle={iconContentTitle}
          />
        </div>
        <div
          className={`relative scrollBar-container md:col-span-2 ${
            darkTheme ? "bg-[#262626]" : "bg-White"
          }`}
        >
          <MiddleHome
            loadBodyData={bodyData}
            containerRef={containerRef}
            containerRefWrapper={containerRefWrapper}
            isFeedbackModal={isFeedbackModal}
            setIsFeedbackModal={setIsFeedbackModal}
            feedbackModalToggle={feedbackModalToggle}
            setIsFeedbackModalPosi={setIsFeedbackModalPosi}
            feedbackModalTogglePosi={feedbackModalTogglePosi}
            handlePrevBackBtn={handlePrevBackBtn}
            isPrevBtn={isPrevBtn}
          />

          {/* FOOTER  */}
          {isFooterVisible && (
            <div className={`absolute w-full bottom-0 z-30`}>
              <div
                className={`flex items-center justify-between h-[40px] bg-Black text-White px-2 md:px-4 gap-1 leading-3`}
              >
                <div>
                  <img
                    src={FooterLogo}
                    className={`w-24 md:w-32 pl-1`}
                    alt="logo"
                  />
                </div>
                <div className={`text-[12px] md:text-[14px]`}>
                  &copy; {currentYear} CloudFrame, Inc. All rights reserved.
                </div>
              </div>
            </div>
          )}
        </div>

        {/* <div className="scrollBar-container">
          <RightHome
            elementsContent={elementsContent}
            highlightElement={highlightElement}
            activeElement={activeElement}
          />
        </div> */}
      </div>

      {/* FOOTER  */}

      {/* {isFooterVisible && (
        <div className={``}>
          <div
            className={`flex items-center justify-between h-[40px] bg-Black text-White px-2 md:px-4 gap-1 leading-3`}
          >
            <div>
              <img
                src={FooterLogo}
                className={`w-24 md:w-32 pl-1`}
                alt="logo"
              />
            </div>
            <div className={`text-[12px] md:text-[14px]`}>
              &copy; {currentYear} CloudFrame, Inc. All rights reserved.
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}
