import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserContextProvider } from "./components/UserContext";
import { Toaster } from "react-hot-toast";
import { msalConfig } from "./msal-login/auth-files/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import { store } from "./components/Redux/store";
import { ChatBotContextProvider } from "./context/chat-bot/ChatBotContext";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <UserContextProvider>
          <ChatBotContextProvider>
            <Toaster />
            <App />
          </ChatBotContextProvider>
        </UserContextProvider>
      </MsalProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
