import React, { useContext } from "react";
import { motion } from "framer-motion";
import { UserContext } from "../../UserContext";

export default function ArticleNotFoundByVersionModal() {
  const {
    darkTheme,
    setIsVersionArticleNotFound,
    storeClickedVersion,
    setStoreClickedVersion,
  } = useContext(UserContext);

  const goToClickedVersionFunc = () => {
    const url = window.location.href;
    const parsedUrl = new URL(url);
    window.location.href = `${parsedUrl.origin}/${storeClickedVersion}`;
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 1, y: 25 }}
      transition={{ delay: 0.2, type: "easeInOut", duration: 0.5 }}
      // className={`relative mx-4 w-full md:w-[380px] rounded-xl p-2 md:p-4 shadow ${
      //   darkTheme ? "bg-DarkGray" : "bg-Bg"
      // }`}
      // Date: 6 March 2025 
      className={`relative mx-4 w-full md:max-w-[500px] rounded-xl p-2 md:p-4 shadow ${
        darkTheme ? "bg-DarkGray" : "bg-Bg"
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex items-center justify-center flex-col gap-4">
        <div>
          <div className="my-1 text-center">Article Not Found!</div>
          <div className={`text-sm ${darkTheme ? "" : ""}`}>
            The current article is not available in the selected version{" "}
            <b>{storeClickedVersion}</b>
          </div>
        </div>

        <div className={`flex items-center justify-center gap-2`}>
          <button
            className={`py-1 px-4 rounded-xl transition-all ${
              darkTheme
                ? "bg-Icon active:bg-gray hover:bg-LightBlack"
                : "bg-Border active:bg-White hover:bg-Hover"
            }`}
            onClick={() => {
              setIsVersionArticleNotFound(false);
              setStoreClickedVersion("");
            }}
          >
            Go Back
          </button>

          <button
            className={`py-1 px-4 rounded-xl transition-all ${
              darkTheme
                ? "bg-Icon active:bg-gray hover:bg-LightBlack"
                : "bg-Border active:bg-White hover:bg-Hover"
            }`}
            onClick={() => goToClickedVersionFunc()}
          >
            Continue to Version {storeClickedVersion}
          </button>
        </div>
      </div>
    </motion.div>
  );
}
