import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";
import toast from "react-hot-toast";
import { BaseURL } from "../BaseURL";
import GetLocalStorageValue from "../reusable/GetLocalStorageValue";
import Button from "../reusable/Button";

export function Callback() {
  const {
    darkTheme,
    setCodeNavigatorToken,
    tokenMode,
    setTokenMode,
    setResToken,
    setGraphData,
    setUserLoginInfo,
    setCnUserInfo,
  } = useContext(UserContext);
  const navigate = useNavigate();

  const [cnErrorHandlerLoader, setCnErrorHandlerLoader] = useState(false);
  const [cnCredFailedLoader, setCnCredFailedLoader] = useState(false);
  const [cnFailedFetchLoader, setCnFailedFetchLoader] = useState(false);
  const [versionElseFailedFetchLoader, setVersionElseFailedFetchLoader] =
    useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("token");

    if (code) {
      exchangeCodeForToken(code);
    } else {
      console.error("Authorization code not found");
    }
    // console.log("tokennnn", code);
  }, []);

  async function exchangeCodeForToken(code) {
    const codeVerifier = localStorage.getItem("access_token");
    const tokenEndpoint = `${BaseURL}/get-accesstoken-from-accesstoken`;

    const tokenDecode = decodeURI(code);
    const data = {
      token: code,
    };

    // const formBody = Object.keys(data)
    //   .map(
    //     (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    //   )
    //   .join("&");

    try {
      const response = await fetch(tokenEndpoint, {
        method: "POST",
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded",
          // "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: `Bearer ${code}`,
          Authorization: `Bearer ${tokenDecode}`,
        },
        body: "",
        // mode: 'cors' // Ensure 'cors' mode is used
        // credentials: "include"
      });

      const tokenData = await response.json();
      // console.log("Token response", tokenData);

      if (tokenData.access_token) {
        // sessionStorage.setItem("access_token", tokenData.access_token);
        // setTokenMode("")
        localStorage.removeItem("access_token");
        localStorage.removeItem("previousUrl");
        localStorage.removeItem("currentUrl");
        localStorage.removeItem("uInfo");
        localStorage.removeItem("msInfo");
        setResToken("");
        setGraphData(null);
        localStorage.setItem("cn_access_token", tokenData.access_token);
        // localStorage.setItem("cn_rt", tokenData.refresh_token);
        sessionStorage.setItem("cn_rtoken", tokenData.refresh_token);

        // if(tokenData?.customer_name !== null){
        // sessionStorage.setItem("cn_customer_name", tokenData.customer_name);
        // }else{
        // sessionStorage.removeItem("cn_customer_name")
        // }

        // if(tokenData?.platform_version !== null){
        // sessionStorage.setItem("cn_platform_version", tokenData.platform_version);
        // }else{
        // sessionStorage.removeItem("cn_platform_version");
        // }

        // Date: 12 Dec, 2024
        // Ref: Email/Meeting on 11 Dec, 2024 from Manigandan to add cn Username and version.
        // Changes: Storing cn Username and version.
        if (tokenData?.customer_name || tokenData?.platform_version) {
          const userInfoFromCN = {
            customer_name: tokenData.customer_name || "",
            platform_version: tokenData.platform_version || "",
          };
          localStorage.setItem("cn_userInfo", JSON.stringify(userInfoFromCN));
          const gettingCnUserInfoData = await GetLocalStorageValue(
            "cn_userInfo",
            {}
          );
          setCnUserInfo(gettingCnUserInfoData);
          // setCnUserInfo(userInfoFromCN);
        }

        setTokenMode("codenavigator");
        setCodeNavigatorToken(tokenData.access_token);
        setUserLoginInfo("");
        // navigate("/protected");
        // toast.success("Credentials Successful");
        navigate("/"); // imp.

        // Date: 20 Jan 2025
        // Changes: Added new logic to getting cn version controlled articles
        const gettingVersionNameByCN = async (v) => {
          try {
            const res = await fetch(`${BaseURL}/codenavigator/versions`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${tokenData.access_token}`,
              },
            });
            var vdata = await res.json();
            if (res.ok) {
              const version = await vdata.data.find((item) => item.slug === v);
              if (version.slug) {
                const versionSlug = await version.slug;
                localStorage.setItem("versionNameId", versionSlug);
                // navigate("/");
                window.location.href = "/";
              } else {
                setVersionElseFailedFetchLoader(true);
                // toast.error("Version not available");
                // return navigate("/version_not_available_with_cn");
                // return (window.location.href = "/version_not_available");
                // Date: 15 March 2025
                // Date: 17 March 2025
                const fallbackVer = vdata?.fallback_version?.slug;

                const userInfoFromCN = {
                  customer_name: tokenData.customer_name || "",
                  platform_version: fallbackVer || "",
                };
                localStorage.setItem(
                  "cn_userInfo",
                  JSON.stringify(userInfoFromCN)
                );
                const gettingCnUserInfoData = await GetLocalStorageValue(
                  "cn_userInfo",
                  {}
                );
                setCnUserInfo(gettingCnUserInfoData);
                return navigate("/version_not_available_with_cn", {
                  state: { cn_fallback_version: fallbackVer },
                });
              }
            }
          } catch (err) {
            // Date: 21 Jan 2025
            if (err.message === "Failed to fetch") {
              // toast.error(
              //   "Network error: Unable to fetch data. Please check your connection."
              // );
              setCnFailedFetchLoader(true);
            } else {
              // toast.error(`An error occurred: ${err.message}`);
              setVersionElseFailedFetchLoader(true);
              // toast.error(`Version not available`);
              // Date: 15 March 2025
              // Date: 17 March 2025
              const fallbackVer = vdata?.fallback_version?.slug;
              if (vdata?.fallback_version?.slug) {
                const userInfoFromCN = {
                  customer_name: tokenData.customer_name || "",
                  platform_version: fallbackVer || "",
                };
                localStorage.setItem(
                  "cn_userInfo",
                  JSON.stringify(userInfoFromCN)
                );
                const gettingCnUserInfoData = await GetLocalStorageValue(
                  "cn_userInfo",
                  {}
                );
                setCnUserInfo(gettingCnUserInfoData);
              }
              return navigate("/version_not_available_with_cn", {
                state: { cn_fallback_version: fallbackVer },
              });
              // return navigate("/version_not_available_with_cn");
            }
          }
        };
        // Date: 21 Jan 2025
        // await gettingVersionNameByCN(tokenData.platform_version);
        await gettingVersionNameByCN(tokenData.platform_version);
        // navigate("/")
      } else {
        // navigate("/");
        // toast.error("Credentials failed !");
        // console.error("Token exchange failed");

        // date: 24 nov 2024
        // toast.error("Credentials failed !");
        // localStorage.removeItem("access_token");
        // localStorage.removeItem("cn_access_token");
        // localStorage.removeItem("cn_rt");
        // localStorage.removeItem("uInfo");
        // localStorage.removeItem("msInfo");
        // setResToken("");
        // setCodeNavigatorToken("");
        // setGraphData(null);
        // navigate("/");
        // setTokenMode("personal")

        // AGIAN 24 NOV 2024
        // toast.error("Credentials failed !");
        // const goingHomePageFunc = () => {
        //   setResToken("");
        //   setCodeNavigatorToken("");
        //   // localStorage.removeItem("access_token");
        //   localStorage.removeItem("cn_access_token");
        //   // window.location.reload();
        // };
        // goingHomePageFunc();
        // navigate("/");
        // window.location.reload();

        // window.location.href = "/";

        // const goingLoginFunc = () => {
        //   setResToken("");
        //   setCodeNavigatorToken("");
        //   localStorage.removeItem("access_token");
        //   localStorage.removeItem("cn_access_token");
        //   window.location.reload();
        // };
        // return (
        //   <div className="flex items-center justify-center flex-col h-screen px-3 gap-y-4">
        //     {/* <div className={`text-lg`}>404</div> */}
        //     <div className="{`text-lg`}">Session has been expired.</div>
        //     <button
        //       type="submit"
        //       className={`px-11 py-2 rounded-full bg-lighGray text-Black flex items-center gap-1 flex-wrap transition-all font-medium font-Barlow_medium hover:bg-White active:bg-opacity-60`}
        //       onClick={(e) => goingLoginFunc(e)}
        //     >
        //       Go to Login Page
        //     </button>
        //   </div>
        // );

        // Date: 20 Jan 2025
        // Changes: Added new logic to getting cn version controlled articles
        setCnCredFailedLoader(true);
      }
    } catch (error) {
      // toast.error("There are issue on server");
      // setTokenMode("personal")
      // navigate("/");
      // window.location.reload();

      // window.location.href = "/";

      // const goingHomePageFunc = () => {
      //   window.location.reload();
      // };
      // goingHomePageFunc();
      // navigate("/");
      console.error("Error from cn for token", error);

      // // const goingLoginFunc = () => {
      // //   setResToken("");
      // //   setCodeNavigatorToken("");
      // //   localStorage.removeItem("access_token");
      // //   localStorage.removeItem("cn_access_token");
      // //   window.location.reload();
      // // };
      // return (
      //   <div className="flex items-center justify-center flex-col h-screen px-3 gap-y-4">
      //     {/* <div className={`text-lg`}>404</div> */}
      //     {/* <div className="{`text-lg`}">Session has been expired.</div> */}
      //     <button
      //       type="submit"
      //       className={`px-11 py-2 rounded-full bg-lighGray text-Black flex items-center gap-1 flex-wrap transition-all font-medium font-Barlow_medium hover:bg-White active:bg-opacity-60`}
      //       // onClick={(e) => goingLoginFunc(e)}
      //       onClick={() => navigate("/")}
      //     >
      //       Go to Home Page
      //     </button>
      //   </div>
      // );

      // Date: 20 Jan 2025
      // Changes: Added new codes/logic to getting cn version controlled articles
      if (error.message === "Failed to fetch") {
        // toast.error(
        //   "Network error: Unable to fetch data. Please check your connection."
        // );
        setCnFailedFetchLoader(true);
      } else {
        toast.error(`An error occurred: ${error.message}`);
        setCnFailedFetchLoader(true);
      }
    }
  }

  // Date: 20 Jan 2025
  // Changes: Added new codes/logic to getting cn version controlled articles
  const gettingLoginFunc = () => {
    window.location.href = "/";
  };
  // VERSION NOT MATCHED LOADING
  if (cnErrorHandlerLoader) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        <div className={`${darkTheme ? "text-Tomato" : "text-Tomato2"}`}>
          Data not available in the selected version
        </div>
        <div
          className={`text-[14px] ${
            darkTheme ? "text-DarkGray" : "text-lighGray"
          }`}
        >
          Please Login
        </div>
        <Button onClick={() => gettingLoginFunc()} className={`!mt-4`}>
          Login
        </Button>
      </div>
    );
  }
  // credentials Failed LOADING
  if (cnCredFailedLoader) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        <div className={`${darkTheme ? "text-Tomato" : "text-Tomato2"}`}>
          Credentials Failed !
        </div>
        <div
          className={`text-[14px] ${
            darkTheme ? "text-DarkGray" : "text-lighGray"
          }`}
        >
          Please Login
        </div>
        <Button onClick={() => gettingLoginFunc()} className={`!mt-4`}>
          Login
        </Button>
      </div>
    );
  }
  // Failed to fetch LOADING
  const gettingRefreshFunc = () => {
    window.location.href = "/";
  };
  if (cnFailedFetchLoader) {
    return (
      <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
        <div className={`${darkTheme ? "text-Tomato" : "text-Tomato2"}`}>
          Can't connect to the network
        </div>
        <div
          className={`text-[14px] ${
            darkTheme ? "text-DarkGray" : "text-lighGray"
          }`}
        >
          Please connect to the network
        </div>
        <Button onClick={() => gettingRefreshFunc()} className={`!mt-4`}>
          Refresh
        </Button>
      </div>
    );
  }

  return (
    // <div className="flex items-center justify-center h-screen">Loading..</div>
    <div className="flex items-center justify-center h-screen p-4">
      <div class={`loading`}>
        <span class="letter text-cfBlue">C</span>
        <span class="letter text-cfBlue">l</span>
        <span class="letter text-cfBlue">o</span>
        <span class="letter text-cfBlue">u</span>
        <span class="letter text-cfBlue">d</span>
        <span class="letter">F</span>
        <span class="letter">r</span>
        <span class="letter">a</span>
        <span class="letter">m</span>
        <span class="letter">e</span>
      </div>

      {/* LOADER : VERSION SLUG NOT MATCHED */}
      {versionElseFailedFetchLoader && (
        <div
          className={`fixed z-[3000] flex items-center justify-center top-0 left-0 bottom-0 right-0 ${
            darkTheme ? "bg-[#262626]" : "bg-[#efe0d8]"
          }`}
        >
          Version not available
        </div>
      )}
    </div>
  );
}
