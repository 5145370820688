import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  // isFooterVisible, setIsFooterVisible,
} from "react";
import { UserContext } from "../../UserContext";
import DOMPurify from "dompurify";
import { motion } from "framer-motion";
import { useReactToPrint } from "react-to-print";
import { MdLocalPrintshop } from "react-icons/md";
// import DOMParser from "dom-parser";
import { HiOutlineHandThumbUp } from "react-icons/hi2";
import { HiOutlineHandThumbDown } from "react-icons/hi2";
import { Tooltip } from "react-tippy";

import { IoIosArrowForward } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { CustomConfirmDialog } from "../../reusable/CustomConfirmDialog";
import { TbMessageHeart } from "react-icons/tb";
import { SideBySideVersionComp } from "./SideBySideVersionComp";
import { CompareVersionSelectOldArticle } from "../../reusable/CompareVersionSelectOldArticle";
import { IoInformationCircleOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import Button from "../../reusable/Button";
import { CompareVersionSelectNewArticle } from "../../reusable/CompareVersionSelectNewArticle";
// import {
//   newEmptyCompArticle,
//   oldEmptyCompArticle,
// } from "../../Redux/features/CompareArticlesSlice";
import { useDispatch } from "react-redux";
import { defaultStateNewArticleSliceFunc } from "../../Redux/features/CompareNewArticleSlice";
import { defaultStateOldArticleSliceFunc } from "../../Redux/features/CompareOldArticleSlice";
// import darkCompareIcon from "../../../img/dark-compare-96.png"
// import lightCompareIcon from "../../../img/light-compare-96.png"
import { MdOutlineCompare } from "react-icons/md";

export default function MiddleHome({
  loadBodyData,
  containerRef,
  containerRefWrapper,
  isFeedbackModal,
  setIsFeedbackModal,
  feedbackModalToggle,
  setIsFeedbackModalPosi,
  feedbackModalTogglePosi,
  handlePrevBackBtn,
  isPrevBtn,
}) {
  const {
    darkTheme,
    bodyData,
    breadCrumb,
    breadCrumbCategory,
    resToken,
    selectedHighlight,
    isOpenLink,
    isFooterVisible,
    setIsFooterVisible,
    allowFeedback,
    versionName,
    codeNavigatorToken,
    // currentArticleAddrRefresh, setCurrentArticleAddrRefresh,
  } = useContext(UserContext);

  // Date: 22 Jan 2025
  // Ref: meeting for comparison version articles
  // Changes: Added new codes to achieve compare version articles.
  // const [compareVersionLoader, setCompareVersionLoader] = useState(true);
  // const [isVersionSelected, setIsVersionSelected] = useState(false);
  const [compareOldVersionName, setCompareOldVersionName] = useState("");
  const [compareNewVersionName, setCompareNewVersionName] = useState("");
  // Date: 24 Jan 2025
  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Date: 28 Feb 2025
  useEffect(() => {
    localStorage.setItem("currentArticleAddr", location.pathname);
    // setCurrentArticleAddrRefresh(!currentArticleAddrRefresh);
  }, [location]);
  // let getCurrentArticleAddress = localStorage.getItem("currentArticleAddr");
  // console.log("getCurr=>", getCurrentArticleAddress);

  const getPreviousUrlArticle = localStorage.getItem("previousUrl");

  // // 5 Feb 2025
  // let getPreviousUrlArticle
  // useEffect(() => {
  //   getPreviousUrlArticle = localStorage.getItem("previousUrl");
  // }, [location]);

  const fileIcons = {
    pdf: "./icons/pdf-icon.png",
    txt: "./icons/txt-icon.png",
    xlsx: "./icons/xlsx-icon.png",
    java: "./icons/java-icon.png",
    zip: "./icons/zip-icon.png",
  };
  // Function to inject file icons
  const injectFileIcons = (htmlString) => {
    // Create a parser to parse the HTML string
    const parser = new DOMParser();
    const dom = parser.parseFromString(htmlString, "text/html");

    // Find all <a> tags in the parsed HTML
    const anchorTags = dom.getElementsByTagName("a");

    // Iterate through each <a> tag and check its href attribute
    Array.from(anchorTags).forEach((anchor) => {
      const href = anchor.getAttribute("href");
      anchor.style.cssText = `text-decoration: none;`;
      anchor.style.color = darkTheme ? "#11d9f0" : "#00B3F0";
      if (href) {
        anchor.setAttribute("target", "_blank");
        // console.log('aatttt', anchor)

        // Get the file extension from the href attribute
        const extension = href.split(".").pop();
        if (fileIcons[extension]) {
          // Create an <img> element with the corresponding icon
          const img = document.createElement("img");
          img.setAttribute("src", fileIcons[extension]);
          img.setAttribute("alt", `${extension} icon`);
          img.style.marginLeft = "5px";
          img.style.width = "30px"; // Optional: Set the width of the icon

          // Append the <img> element to the <a> tag
          anchor.appendChild(img);

          anchor.removeAttribute("target");
          anchor.setAttribute("download", "");
          // console.log("ancc", anchor);
        }
      }
      // console.log("anccHref", href);
    });
    // console.log("anccTags", anchorTags);

    // Return the modified HTML as a string
    return dom.body.innerHTML;
  };

  // const sanitizedHTML = DOMPurify.sanitize(bodyData.body);
  // const sanitizedHTML = DOMPurify.sanitize(
  //   injectFileIcons(loadBodyData.body ? loadBodyData.body : bodyData)
  // );
  const sanitizedHTML = DOMPurify.sanitize(
    loadBodyData.body ? loadBodyData.body : bodyData
  );

  // Function to print the content
  const handlePrint = () => {
    const printWindow = window.open("", "", "width=800,height=600"); // Open a new window

    // Get the inner HTML of the content div
    const contentToPrint = containerRef.current.innerHTML;

    // Write the HTML content into the new window and call the print function
    printWindow.document.write(`
        <html>
          <head>
            <title>Print Content</title>
            <style>
              /* You can add styles here that only apply to the printed content */
              body {
                font-family: Arial, sans-serif;
              }
            </style>
          </head>
          <body>
            <div class="print-container prose">
              ${contentToPrint}
            </div>
          </body>
        </html>
      `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  // FOOTER SHOW WHEN THE SCROLL IS COMPLETED
  // const handleScroll = () => {
  //   if (containerRefWrapper.current) {
  //     const { scrollTop, scrollHeight, clientHeight } =
  //       containerRefWrapper.current;

  //     const threshold = scrollHeight * 0.95; // 95% of the scroll height
  //     // if (scrollTop + clientHeight >= threshold) {
  //     if (scrollTop + clientHeight >= scrollHeight) {
  //       setIsFooterVisible(true);
  //     } else {
  //       setIsFooterVisible(false);
  //     }
  //   }
  // };
  // Date: 26 March 2025 
  // Ref: "As per Manigandan"
  // Changes: "footer not show some article or any screen size"
  const handleScroll = () => {
    if (containerRefWrapper.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        containerRefWrapper.current;

      const threshold = scrollHeight * 0.95; // 95% of the scroll height
      if (scrollTop + clientHeight >= threshold) {
        // if (scrollTop + clientHeight >= scrollHeight) {
        setIsFooterVisible(true);
      } else {
        setIsFooterVisible(false);
      }
    }
  };

  // FEEDBACK CONFIRMATION WHEN ALLREADY
  const showFeedbackAllowModal = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomConfirmDialog
            title="Do you want to provide feedback again?"
            message="You have already given feedback on this article"
            onConfirm={() => {
              // Handle confirm action
              setIsFeedbackModal(true);
              onClose();
            }}
            onCancel={onClose}
          />
        );
      },
    });
  };

  const categArr = breadCrumbCategory?.split(" ");
  const titelArr = breadCrumb?.split(" ");

  // Date: 23 Jan 2025
  const closeVersionCompBoxFunc = () => {
    setIsCompareModalOpen(!isCompareModalOpen);
    setCompareOldVersionName("");
    setCompareNewVersionName("");
    // dispatch(oldEmptyCompArticle());
    // dispatch(newEmptyCompArticle());
    dispatch(defaultStateOldArticleSliceFunc());
    dispatch(defaultStateNewArticleSliceFunc());
    setCompareOldVersionName("");
    setCompareNewVersionName("");
  };
  return (
    <div
      onScroll={handleScroll}
      id="middle-content"
      ref={containerRefWrapper}
      className={`pb-8 ${isFooterVisible
        ? "When-Footer-Occured-HeightMiddle"
        : "When-Footer-Occured-HeightMiddle"
        } overflow-auto ${darkTheme ? "bg-[#262626] scrollBar-dark" : "bg-White scrollBar"
        }`}
    >
      {/* BREADCRUMB  */}
      <div
        className={`px-4 md:px-6 py-1.5 flex items-center gap-2 justify-between ${darkTheme ? "bg-[#1c3c68]" : "bg-[#dbe5ee]"
          }`}
      >
        <div className="line-clamp-1 text-[15px]">
          {/* {breadCrumbCategory} <span>/</span> {breadCrumb} */}
          {`${categArr[0]} ${categArr.length > 1 ? categArr[1] : ""}${categArr?.length > 2 ? "..." : ""
            }`}{" "}
          <span>/</span>{" "}
          {`${titelArr[0]} ${titelArr.length > 1 ? titelArr[1] : ""}${titelArr?.length > 2 ? "..." : ""
            }`}
        </div>

        {/* BUTTONS OF BREADCRUMB  */}
        <div className={`flex items-center gap-4`}>
          {/* Date: 5 Feb 2025  */}
          {/* Ref: "As per Manigandhan instruction on meeting." */}
          {/* Changes: "Added compare button" */}

          {/* Date: 27 Feb 2025  */}
          {/* {versionName && (
            <Tooltip
              title="Compare Articles"
              position="bottom"
              trigger="mouseenter"
              size="regular"
              delay={300}
            >
              <button
                className={`flex items-center justify-center gap-1 px-2 py-1 text-sm rounded-xl shadow transition-all cursor-pointer ${
                  darkTheme
                    ? "active:bg-gray hover:bg-bgCF"
                    : "active:bg-[#bdc6ce] hover:bg-bgCF hover:text-White"
                }`}
                onClick={() => closeVersionCompBoxFunc()}
              >
                <MdOutlineCompare className={`w-5 h-5`} />
              </button>
            </Tooltip>
          )} */}

          {/* FEEDBACK MODAL  */}
          {(resToken || codeNavigatorToken) && (
            <div>
              {/* <div>Was this topic helpful?</div> */}
              <div className={`flex items-center gap-6`}>
                <Tooltip
                  // title="Was this topic helpful?"
                  title="Feedback"
                  position="top"
                  trigger="mouseenter"
                  // theme={darkTheme? "dark": "light"}
                  size="regular"
                  // delay={1000}
                  delay={300}
                >
                  <button
                    className={`cursor-pointer transition-all rounded-xl px-2 py-1 shadow ${darkTheme
                      ? "active:bg-gray hover:bg-bgCF"
                      : "active:bg-[#bdc6ce] hover:bg-bgCF hover:text-White"
                      }`}
                    onClick={() => {
                      allowFeedback > 0
                        ? showFeedbackAllowModal()
                        : feedbackModalToggle();
                    }}
                  >
                    {/* <HiOutlineHandThumbUp className={`w-5 h-5`} /> */}
                    <TbMessageHeart className={`w-5 h-5`} />
                  </button>
                </Tooltip>
                {/* <div
              className={`cursor-pointer transition-all rounded-xl p-1 ${
                darkTheme ? "active:bg-DarkGray" : "active:bg-gray"
              }`}
              onClick={() => {
                allowFeedback
                  ? showFeedbackAllowModal(setIsFeedbackModal)
                  : feedbackModalToggle();
              }}
            >
              <Tooltip
                title="Negative Feedback"
                position="top"
                trigger="mouseenter"
                // theme={darkTheme? "dark": "light"}
                size="regular"
                delay={1000}
              >
                <HiOutlineHandThumbDown className={`w-5 h-5`} />
              </Tooltip>
            </div> */}
              </div>
            </div>
          )}

          {/* PRINT BTN  */}
          <Tooltip
            title="Print"
            position="bottom"
            trigger="mouseenter"
            // theme={darkTheme? "dark": "light"}
            size="regular"
            // delay={1000}
            delay={300}
          >
            <button
              className={`flex items-center justify-center gap-1 px-2 py-1 text-sm rounded-xl shadow transition-all cursor-pointer ${darkTheme
                ? "active:bg-gray hover:bg-bgCF"
                : "active:bg-[#bdc6ce] hover:bg-bgCF hover:text-White"
                }`}
              onClick={() => handlePrint()}
            >
              <MdLocalPrintshop className={`w-5 h-5`} />
            </button>
          </Tooltip>
        </div>
      </div>

      {/* Date: 5 Feb 2025  */}
      {/* Ref: "As per Manigandhan instruction on meeting." */}
      {/* Changes: "Compare button comments/remove and attached with breadcrumb" */}
      {/* COMPARISON VERSION ARTICLES */}
      {/* <div className={`py-6`}>
        <div
          className={`text-[14px] mx-4 p-2.5 rounded-lg flex gap-1 items-center flex-wrap ${
            darkTheme ? "border-[#5f6266]" : "border-[#cfdfe0]"
          }`}
        >
          <div className={`flex items-center gap-1`}>
            <IoInformationCircleOutline
              className={`w-6 h-6 ${
                darkTheme ? "text-[#0f99ff]" : "text-bgCF"
              }`}
            />
            <div>If you want to compare this article with another version</div>
          </div>

          <Button
            className={`!rounded-full ${
              darkTheme
                ? "!bg-[#1c3c68] !text-White"
                : "!bg-[#dbe5ee] !text-Black"
            }`}
            onClick={() => closeVersionCompBoxFunc()}
          >
            Compare
          </Button>
        </div> */}
      {isCompareModalOpen && (
        <div
          className={`fixed z-40 top-0 left-0 right-0 bottom-0 bg-[#c8c8da6b] backdrop-blur-md h-screen`}
        // onClick={() => closeVersionCompBoxFunc()}
        >
          <motion.div
            initial={{ opacity: 0, y: 25 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 25 }}
            transition={{ delay: 0.2, type: "easeInOut", duration: 0.5 }}
            // className={`m-4 rounded-lg p-4 overflow-auto h-[96.5%] md:w-[735px] lg:w-[994px] xl:w-[1250px] 2xl:w-[1500px] md:mx-auto`}
            className={`m-4 rounded-lg md:p-4 overflow-auto h-[96.5%] 2xl:w-[1500px] md:mx-auto ${darkTheme ? "scrollBar-dark" : "scrollBar"
              }`}
          // onClick={(e) => e.stopPropagation()}
          >
            <div>
              <div className={`flex justify-end`}>
                <Button
                  onClick={() => closeVersionCompBoxFunc()}
                  className={`!rounded-full !p-2 !mb-2`}
                >
                  <IoClose className={`w-5 h-5`} />
                </Button>
              </div>
              <div className={`text-center mb-1`}>
                Compare Article Between Versions
              </div>
            </div>
            <div className={`flex gap-2`}>
              <div
                className={`flex items-center mb-2 justify-around w-full rounded-lg ${darkTheme ? "bg-[#262626]" : "bg-[#ffffff]"
                  }`}
              >
                <div className={`flex items-center`}>
                  <CompareVersionSelectOldArticle
                    selectedOption={compareOldVersionName}
                    setSelectedOption={setCompareOldVersionName}
                  // setIsVersionSelected={setIsVersionSelected}
                  />
                </div>
                <div className={`flex items-center`}>
                  <CompareVersionSelectNewArticle
                    selectedOption={compareNewVersionName}
                    setSelectedOption={setCompareNewVersionName}
                  // setIsVersionSelected={setIsVersionSelected}
                  />
                </div>
              </div>
            </div>
            <SideBySideVersionComp
              // isVersionSelected={isVersionSelected}
              // setIsVersionSelected={setIsVersionSelected}
              compareOldVersionName={compareOldVersionName}
              compareNewVersionName={compareNewVersionName}
              setCompareOldVersionName={setCompareOldVersionName}
              setCompareNewVersionName={setCompareNewVersionName}
            // setCompareVersionLoader={setCompareVersionLoader}
            // compareVersionLoader={compareVersionLoader}
            // loadBodyData={loadBodyData}
            //               oldArticle={bodyData}
            //               newArticle={`<h1>Updated Article</h1>
            // <p>This is the updated article content with changes.</p>
            // <pre>Code Block 1</pre>
            // <pre>Code Block 2</pre>`}
            />
          </motion.div>
        </div>
      )}
      {/* </div> */}

      <motion.div
        id="middlePage"
        ref={containerRef}
        className={`print-container ${isFooterVisible
          ? "When-Footer-Occured-HeightMiddle"
          : "When-Footer-Occured-HeightMiddle"
          } prose min-w-full px-4 md:px-6 py-4 ${darkTheme ? "prose-moon" : "prose-sun"
          }`}
        key={loadBodyData.body ? loadBodyData.slug : bodyData}
        initial={{ opacity: 0, y: 25 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 1, y: 25 }}
        transition={{ delay: 0.3, type: "easeInOut", duration: 0.7 }}
        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      />

      {/* {!isOpenLink && isPrevBtn && ( */}
      {!isOpenLink &&
        getPreviousUrlArticle !== "/" &&
        getPreviousUrlArticle && (
          <div
            className={`absolute right-4 ${isFooterVisible ? "bottom-14" : "bottom-4"
              } z-20`}
          >
            <button
              className={`flex items-center justify-center gap-1 px-4 py-1 text-sm rounded-full shadow transition-all ${darkTheme
                ? "bg-[#0a61dc81] hover:bg-bgCF active:bg-[#3786f5] text-White"
                : "bg-[#1073ff9a] hover:bg-bgCF active:bg-[#3786f5] text-White"
                }`}
              onClick={() => handlePrevBackBtn()}
            // onClick={() => navigate(-1)}
            // onClick={() => navigate(`/prerequisites`, { replace: true })}
            >
              Previous
              <IoIosArrowForward className={`w-5 h-5 rotate-180`} />
            </button>
          </div>
        )}
    </div>
  );
}
