import React, { useContext, useState } from "react";
import { UserContext } from "../UserContext";
import { motion } from "framer-motion";
import {
  SunIcon,
  MagnifyingGlassIcon,
  PowerIcon,
} from "@heroicons/react/24/outline";
import { AiFillMoon } from "react-icons/ai";
import { HiMenu } from "react-icons/hi";
import { FaXmark } from "react-icons/fa6";
import Input from "../reusable/Input";
import { Link, useNavigate } from "react-router-dom";
import { HiMiniPower } from "react-icons/hi2";
import { FaUser } from "react-icons/fa6";
import { CustomConfirmDialog } from "../reusable/CustomConfirmDialog";
import { confirmAlert } from "react-confirm-alert";
import OutsideClickHandler from "react-outside-click-handler";
import useOutsideClick from "../reusable/UseOutsideClick";
// import Logo from "../../img/logo-white.webp";
import Logo from "../../img/logo-white-blue.webp";
import { AiOutlineLogin } from "react-icons/ai";
import MSLogoutModal from "./slices/MSLogoutModal";
import { VersionSelect } from "../reusable/VersionSelect";
import { LuPanelRightClose } from "react-icons/lu"; // Date: 7 Feb 2025

export default function Header() {
  const {
    darkTheme,
    setDarkTheme,
    setIsSmMenuOpen,
    isSmMenuOpen,
    isSearchOpen,
    setIsSearchOpen,
    setResToken,
    setCodeNavigatorToken,
    isProfileOpen,
    setIsProfileOpen,
    userLoginInfo,
    isOpenLink,
    graphData,
    setGraphData,
    setSelectedHighlight,
    setIsOpenLink,
    cnUserInfo,
    versionName,
    setVersionName,
    // 7 Feb 2025
    removeGrid,
    setRemoveGrid,
    leftBarHide,
    setleftBarHide,
    iconContentTitle,
    setIconContentTitle,
    collapseLeftBarFun,
  } = useContext(UserContext);
  const navigate = useNavigate();

  const [isMsModalOpen, setIsMsModalOpen] = useState(false);
  const ToggleThemeFunc = () => {
    setDarkTheme(!darkTheme);
  };
  const headerMenuToggle = () => {
    setIsSmMenuOpen(!isSmMenuOpen);
  };

  // LOGOUT FUNC
  const logoutFunc = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("cn_access_token");
    localStorage.removeItem("cn_rt");
    localStorage.removeItem("uInfo");
    localStorage.removeItem("msInfo");
    localStorage.removeItem("previousUrl");
    localStorage.removeItem("currentUrl");
    localStorage.removeItem("versionNameId");

    // Date: 12 Dec, 2024
    // Ref: Email/Meeting on 11 Dec, 2024 from Manigandan to add cn Username and version.
    // Changes: Remove Storing cn Username and version.
    localStorage.removeItem("cn_userInfo");
    setSelectedHighlight("");
    setIsOpenLink("");
    setResToken("");
    setCodeNavigatorToken("");
    setGraphData(null);
    navigate("/");
  };
  const showDialog = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomConfirmDialog
            title="Are you sure?"
            message="You want to logout"
            onConfirm={() => {
              // Handle confirm action
              logoutFunc();

              onClose();
            }}
            onCancel={onClose}
          />
        );
      },
    });
  };

  // PROFILE TOGGLE
  const profileToggleHandler = () => {
    setIsProfileOpen((prev) => !prev);
  };
  const profileClick = useOutsideClick(() => setIsProfileOpen(!isProfileOpen));
  return (
    // <div className={`bg-Black max-w-[1680px] mx-auto`}>
    <div className={`max-w-[1740px] mx-auto`}>
      <div
        className={`h-[56px] px-4 sticky top-0 z-40 backdrop-blur-md flex items-center justify-between bg-Black`}
      >
        {/* ${darkTheme ? "bg-Black" : "bg-Border"} */}
        <div className={`flex items-center gap-3`}>
          <div
            onClick={() => headerMenuToggle()}
            className={`block md:hidden cursor-pointer`}
          >
            {isSmMenuOpen ? (
              <FaXmark className={`w-5 h-5 text-White`} />
            ) : (
              // ${darkTheme ? "text-White" : "text-Black"}
              <HiMenu className={`w-5 h-5 text-White`} />
            )}
          </div>

          {/* Date: 7 Feb 2025  */}
          <div
            className={`hidden md:block cursor-pointer`}
            onClick={() => collapseLeftBarFun()}
          >
            {leftBarHide !== "block" ? (
              <LuPanelRightClose className={`w-5 h-5 text-White`} />
            ) : (
              <HiMenu className={`w-5 h-5 text-White`} />
            )}
          </div>
          {/* {darkTheme ? ( */}
          <img src={Logo} className={`w-32 md:w-44`} alt="logo" />
          {/* ) : (
          <img src="./assets/logo-blue-black.webp" className={`w-32 md:w-44`} alt="logo" />
        )} */}
        </div>

        <div className="flex items-center gap-2">
          {/* VERSION CONTROLLER---------START  */}
          {/* {!isOpenLink && (
            <div>
              <VersionSelect
                selectedOption={versionName}
                setSelectedOption={setVersionName}
              />
            </div>
          )} */}
          {/* Date: 27 Feb 2025  */}
          {!isOpenLink && versionName && (
            <div>
              <VersionSelect
                selectedOption={versionName}
                setSelectedOption={setVersionName}
              />
            </div>
          )}
          {/* VERSION CONTROLLER---------END  */}

          {/* <a
              href="https://cloudframe.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="hidden md:block px-3 py-1 text-White"
            >
              CloudFrame
            </a> */}
          {/* SEARCH ======== */}
          <div
            onClick={() => setIsSearchOpen(!isSearchOpen)}
            className={`relative flex items-center rounded-xl bg-DarkGray`}
            // ${darkTheme ? "bg-DarkGray" : "bg-White" }
          >
            <div className={`p-2 cursor-pointer`}>
              <MagnifyingGlassIcon className={`w-5 h-5 text-[#babec5]`} />
              {/* ${darkTheme? "text-[#babec5]" : "text-Icon"} */}
            </div>
            <Input
              placeholder="Search"
              type="text"
              className={`!px-0 !py-1.5 hidden md:block !bg-DarkGray`}
              readOnly
            />
            <div
              className={`absolute right-2 hidden md:block text-[13px] text-[#babec5]`}
            >
              {/* ${darkTheme? "text-[#babec5]" : "text-Icon"} */}
              Ctrl + K
            </div>
          </div>
          <motion.button
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.1 }}
            onClick={() => ToggleThemeFunc()}
            className={`!p-2 rounded-full ${
              darkTheme ? "!bg-bgCF" : "!bg-bgCF"
            }`}
          >
            <motion.div
              initial={{ opacity: 0, rotate: -90 }}
              animate={{ opacity: 1, rotate: 0 }}
              exit={{ opacity: 0, rotate: 90 }}
              transition={{ duration: 0.2 }}
            >
              {darkTheme ? (
                <SunIcon className={`w-5 h-5 !text-White`} />
              ) : (
                <AiFillMoon className={`w-5 h-5 !text-White`} />
              )}
            </motion.div>
          </motion.button>

          {/* PROFILE LOGOUT btn  */}
          <OutsideClickHandler
            onOutsideClick={() => {
              setIsProfileOpen(false);
            }}
          >
            <div>
              <button
                // whileTap={{ scale: 0.9 }}
                // whileHover={{ scale: 1.1 }}
                // onClick={() => profileToggleHandler()}
                onClick={() => {
                  setIsProfileOpen(!isProfileOpen);
                }}
                className={`p-2 rounded-full relative transition-all`}
              >
                {/* <div
                // initial={{ opacity: 0, rotate: -90 }}
                // animate={{ opacity: 1, rotate: 0 }}
                // exit={{ opacity: 0, rotate: 90 }}
                // transition={{ duration: 0.2 }}
                className={`text-sm`}
              >
                <FaUser className={`w-5 h-5 text-hoverClr`} />
              </div> */}
                <FaUser className={`w-5 h-5 text-hoverClr`} />
              </button>
              {isProfileOpen && (
                <div
                  // ref={profileClick}
                  className={`shadow min-w-56 max-w-72 border px-4 py-2 rounded-xl absolute z-40 right-3 top-[62px] ${
                    darkTheme
                      ? "bg-LightBlack border-[#777]"
                      : "bg-eee border-[#ccc]"
                  }`}
                >
                  <ul
                    className={`flex items-start justify-center flex-col gap-1`}
                  >
                    {!isOpenLink && (
                      <li className={`flex items-center gap-1 pt-2 px-2`}>
                        {/* <PowerIcon className={`w-5 h-5`} /> */}
                        {userLoginInfo ? (
                          <span>{userLoginInfo.user_name}</span>
                        ) : cnUserInfo?.customer_name ? (
                          // <span>CN User</span>

                          // Date: 12 Dec, 2024
                          // Ref: Email/Meeting on 11 Dec, 2024 from Manigandan to add cn Username and version.
                          // Changes: Added cn Username and version.
                          <div
                            className={`flex flex-col justify-center items-start gap-y-1`}
                          >
                            <span className={`text-[18px]`}>
                              {cnUserInfo.customer_name}
                            </span>
                            <div className={`text-gray text-[13px]`}>
                              App Version{" "}
                              <span>{cnUserInfo.platform_version}</span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </li>
                    )}
                    {isOpenLink ? (
                      // <Link href="https://askcn.cloudframe.com">
                      <Link
                        to="#"
                        onClick={() =>
                          (window.location.href = "https://ask.cloudframe.com")
                        }
                      >
                        <li
                          // onClick={() => navigate("/")}
                          className={`flex cursor-pointer items-center gap-1 w-full px-2 rounded-xl py-1 transition-all ${
                            darkTheme
                              ? "hover:bg-gray active:bg-opacity-30"
                              : "hover:bg-White active:bg-opacity-30"
                          }`}
                        >
                          <AiOutlineLogin className={`w-5 h-5`} />
                          <span>Login</span>
                        </li>
                      </Link>
                    ) : graphData?.userPrincipalName ? (
                      <li
                        onClick={() => setIsMsModalOpen(!isMsModalOpen)}
                        className={`flex cursor-pointer items-center gap-1 w-full px-2 rounded-xl py-1 transition-all ${
                          darkTheme
                            ? "hover:bg-gray active:bg-opacity-30"
                            : "hover:bg-White active:bg-opacity-30"
                        }`}
                      >
                        <PowerIcon className={`w-5 h-5`} />
                        <span>Logout</span>
                      </li>
                    ) : (
                      <li
                        onClick={() => showDialog()}
                        className={`flex cursor-pointer items-center gap-1 w-full px-2 rounded-xl py-1 transition-all ${
                          darkTheme
                            ? "hover:bg-gray active:bg-opacity-30"
                            : "hover:bg-White active:bg-opacity-30"
                        }`}
                      >
                        <PowerIcon className={`w-5 h-5`} />
                        <span>Logout</span>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>

      {/* MS LOGOUT MODAL BTN  */}
      {isMsModalOpen && (
        <div
          className="absolute z-40 top-0 left-0 right-0 bottom-0 bg-[#c8c8da6b] backdrop-blur h-screen flex items-center justify-center"
          onClick={() => setIsMsModalOpen(!isMsModalOpen)}
        >
          <MSLogoutModal />
        </div>
      )}
    </div>
    // </div>
  );
}
