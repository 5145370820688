import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseURL } from "../../BaseURL";

// Thunk to fetch articles from the API
export const fetchNewArticle = createAsyncThunk(
  "compareNewArticle/fetchNewArticle",
  async (
    { versionSlug, tokenMode, selectedHighlight },
    { rejectWithValue }
  ) => {
    try {
      // ACCESS TOKEN
      const accessToken = localStorage.getItem("access_token");
      // CN ACCESS TOKEN
      const cnAccessToken = localStorage.getItem("cn_access_token");

      const versionData = {
        version: versionSlug,
      };
      // Convert the data to query string
      const queryParams = new URLSearchParams(versionData).toString();
      const response = await fetch(
        `${BaseURL}/${tokenMode}/posts?${queryParams}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${
              tokenMode === "personal" ? accessToken : cnAccessToken
            }`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      //   return data; // Assuming API returns { oldArticle: "...", newArticle: "..." }
      if (response.ok) {
        const updatedData = await data.data.map((item) => {
          return {
            ...item,
            posts: item.posts.map((post) => ({
              ...post,
              name: item.name,
            })),
          };
        });
        // console.log("myAllPoost", updatedData)
        const myAllPosts = await updatedData.flatMap(
          (category) => category.posts
        );
        const newCompArticle = await myAllPosts.filter(
          (post) => post.slug === selectedHighlight
        );
        if (newCompArticle.length > 0) {
          const mainData = { newArticle: newCompArticle[0].body || "" };
          return mainData;
        } else {
          return rejectWithValue(
            `"${selectedHighlight}" Article not avialable in the selected version`
          );
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        return rejectWithValue("Can't connect to the network");
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const CompareNewArticleSlice = createSlice({
  name: "compareNewArticle",
  initialState: {
    newArticle: "",
    isLoadingNewArticle: false,
    errorNewArticle: null,
  },
  reducers: {
    newEmptyCompArticle: (state, action) => {
      state.newArticle = "";
    },
    defaultStateNewArticleSliceFunc: (state, action) => {
      state.newArticle = "";
      state.isLoadingNewArticle = false;
      state.errorNewArticle = null;
    },
    cleanedNewArticle: (state, action) => {
      state.newArticle = action.payload;
    },
  }, // No additional reducers needed for now
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewArticle.pending, (state) => {
        state.isLoadingNewArticle = true;
        state.errorNewArticle = null;
      })
      .addCase(fetchNewArticle.fulfilled, (state, action) => {
        state.isLoadingNewArticle = false;
        // state.oldArticle = action.payload.oldArticle;
        state.newArticle = action.payload.newArticle;
      })
      .addCase(fetchNewArticle.rejected, (state, action) => {
        state.isLoadingNewArticle = false;
        state.errorNewArticle = action.payload;
      });
  },
});
export const {
  newEmptyCompArticle,
  cleanedNewArticle,
  defaultStateNewArticleSliceFunc,
} = CompareNewArticleSlice.actions;
export default CompareNewArticleSlice.reducer;
