import React, { useContext } from "react";
import { UserContext } from "../UserContext";
import Button from "../reusable/Button";

export default function ArticleNotAvailable() {
  const { darkTheme } = useContext(UserContext);
  const gettingRefreshFunc = () => {
    localStorage.removeItem("cn_access_token");
    return (window.location.href = "/");
  };
  return (
    <div className={`h-screen p-4 flex flex-col items-center justify-center`}>
      <div>Article not found</div>
      <div
        className={`text-[14px] ${
          darkTheme ? "text-DarkGray" : "text-LightBlack"
        }`}
      >
        Go back to the home page
      </div>
      <Button onClick={() => gettingRefreshFunc()} className={`!mt-4`}>
        Go back
      </Button>
    </div>
  );
}
