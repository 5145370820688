// Date: 15 Jan, 2025
// Ref: Meeting for ask cn Versions control.
// Changes: Added VersionSelect file for verson switching.

import { useCallback, useContext, useEffect, useState } from "react";
import Select, { components } from "react-select";
import { debounce } from "lodash";
import AsyncSelect from "react-select/async";
import { UserContext } from "../UserContext";
import { BaseURL } from "../BaseURL";
import toast from "react-hot-toast";

export const VersionSelect = ({ selectedOption, setSelectedOption }) => {
  const {
    darkTheme,
    tokenMode,
    setIsVersionNameClicked,
    isVersionNameClicked,
    selectedHighlight,
    setIsVersionArticleNotFound,
    setStoreClickedVersion,
  } = useContext(UserContext);
  // ACCESS TOKEN
  const accessToken = localStorage.getItem("access_token");
  // CN ACCESS TOKEN
  const cnAccessToken = localStorage.getItem("cn_access_token");

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Date: 1 March 2025
  // Ref: "As per Manigandan, to show pop-up box if article not available on perticular version."
  // Changes: New code added.
  const checkingArticleDataFunc = async (vData) => {
    const versionData = {
      version: vData.slug ? vData.slug : "",
    };
    const queryParams = new URLSearchParams(versionData).toString();
    const versionValidate = queryParams ? queryParams : "";

    try {
      const res = await fetch(
        `${BaseURL}/${tokenMode}/posts?${versionValidate}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${
              tokenMode === "personal" ? accessToken : cnAccessToken
            }`,
          },
        }
      );
      const data = await res.json();

      if (res.ok && data?.success) {
        const myAllPosts = await data.data.flatMap(
          (category) => category.posts
        );

        // CHECKING ARTICLE
        const filtered = await myAllPosts.filter(
          (post) => post.slug === selectedHighlight
        );
        if (filtered.length > 0) {
          localStorage.setItem("versionNameId", vData.slug);
          await setIsVersionNameClicked(true);
          setSelectedOption(vData);
        } else {
          // toast.error("Article not found");
          setIsVersionArticleNotFound(true);
          setStoreClickedVersion(vData.slug);
        }
      } else {
        toast.error(`Error: ${res.status} ${res.statusText}`);
      }
    } catch (err) {
      if (err.message === "Failed to fetch") {
        toast.error("Failed to fetch");
      } else {
        toast.error(err.message);
      }
    }
  };
  const stopCheckLinkClickFunc = (sVersion) => {
    toast("Please wait, it's processing..");
    checkingArticleDataFunc(sVersion);
  };

  // useEffect(() => {
  //   const fetchInitialData = async () => {
  //     if (selectedOption !== "") {
  //       setIsLoading(true);
  //       const response = await fetch(
  //         `${BaseURL}/versions?q=${selectedOption}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Accept: "application/json",
  //             Authorization: `Bearer ${
  //               tokenMode === "personal" ? accessToken : cnAccessToken
  //             }`,
  //           },
  //         }
  //       );
  //       const data = await response.json();
  //       const myFilter = data.data.filter(
  //         (item) => item.id === parseInt(selectedOption)
  //       );
  //       const transformedOptions = myFilter.map((item) => ({
  //         value: item.id,
  //         label: item.name,
  //       }));
  //       const myFind = transformedOptions.find(
  //         (item) => item.value === parseInt(selectedOption)
  //       );
  //       setSelectedOption(myFind);
  //       if (data.status === false || data.status === true) {
  //         setIsLoading(false);
  //       }
  //     }
  //   };
  //   fetchInitialData();
  // }, []);
  useEffect(() => {
    const fetchInitialData = async () => {
      if (selectedOption !== "") {
        setIsLoading(true);
        const myFind = options.find(
          // (item) => item.value === parseInt(selectedOption)
          (item) => item.slug === selectedOption
        );
        if (myFind) {
          setSelectedOption(myFind);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    };
    fetchInitialData();
  }, [options]);

  // Function to load options based on user input
  const loadOptions = (inputValue, callback) => {
    const res = fetch(`${BaseURL}/${tokenMode}/versions?q=${inputValue}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${
          tokenMode === "personal" ? accessToken : cnAccessToken
        }`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const options = data.data.map((item) => ({
          label: `Version- ${item.name}`,
          value: item.id,
          slug: item.slug,
        }));
        callback(options);
        setOptions(options);
        // setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        callback([]);
        // setIsLoading(false)
      });
  };
  const debouncedLoadOptions = debounce(loadOptions, 400);

  // Handle option select
  const handleChange = async (option) => {
    // setSelectedOption(option);
    // await setIsVersionNameClicked(false);

    // if (option?.value) {
    //   localStorage.setItem("versionNameId", option.slug);
    //   await setIsVersionNameClicked(true);
    // }
    if (option?.value) {
      // Date: 1 March 2025
      stopCheckLinkClickFunc(option);
    }
  };

  // Custom styling
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      margin: "0 auto",
      color: "white",
    }),
    control: (provided) => ({
      ...provided,
      // backgroundColor: "#000",

      // Date: 4 Feb, 2025
      // Ref: In the meeting with Manigandan, he has said the version dropdown menu style and color are not, please send some sample images to see wich color is good on the version dropdown.
      // Changes: Added new codes for colors.

      // backgroundColor: "#F6F8FC",
      // backgroundColor: "#52565e",
      // backgroundColor: "#b8c0ff",
      // backgroundColor: "#edf6f9",
      // backgroundColor: "#eaf2d7",
      // backgroundColor: "#eee2df",
      // backgroundColor: "#a1cca5",
      // backgroundColor: "#f7b267",
      backgroundColor: "#0a61dc",
      // backgroundColor: "#f5d491",
      // borderColor: "none",
      boxShadow: "none",
      "&:hover": {
        borderColor: "none",
      },
      // borderRadius: "12px",
      borderRadius: "9999px",
      border: "none",
      // padding: "0px 2px",
      // textTransform: "uppercase",
      // border: "2px solid #aaa9",
      fontSize: "14px",
      height: "35px",
      minHeight: "35px",
      padding: "0px",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      // backgroundColor: `${darkTheme ? "#52565e" : "#F6F8FC"}`,
      // textTransform: "uppercase",
      // border: "1px solid #E5E5E5",
      padding: "0 6px",
      width: "166px",
      zIndex: 9999,
    }),
    // menuList: (provided) => ({
    //   ...provided,
    //   backgroundColor: `${darkTheme ? "#52565e" : "#F6F8FC"}`, // Ensure the list inside matches the menu background
    //   // padding: 0,
    // }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#0a61dc"
        : state.isFocused
        ? "#fff"
        : "#fff",
      color: state.isSelected ? "white" : "black",
      "&:active": {
        backgroundColor: "#aaa",
        color: "white",
      },
      padding: "4px 10px",
      borderRadius: "8px",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      // backgroundColor: `${darkTheme ? "#52565e" : "#F6F8FC"}`,
      // padding: "2px 6px",
      // borderRadius: "4px"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
      fontWeight: "400",
      fontSize: "14px",
      // textTransform: "uppercase",
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
      margin: "0px",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#fff" : "#bbb",
      "&:hover": {
        color: "#fff", // Change color on hover
      },
      // paddingRight: "4px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#999",
      display: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: "10px",
    }),
  };

  return (
    <AsyncSelect
      styles={customStyles}
      cacheOptions
      loadOptions={debouncedLoadOptions}
      defaultOptions
      onChange={handleChange}
      value={selectedOption}
      // defaultValue={defValue}
      placeholder="Select"
      isLoading={isLoading}
      // components={{
      //   MenuList: (props) => <MenuList {...props} isLoading={isLoading} />,
      // }}
      closeMenuOnSelect={true}
      className="react-select-container"
      classNamePrefix="react-select"
      // isClearable
      isDisabled={isLoading}
      isSearchable={false}
    />
  );
};
